import { NgModule } from '@angular/core';

import { ShipmentsRoutingModule } from './shipments-routing.module';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { ShipmentsComponent } from './components/shipments.component';
import { ShipmentListingComponent } from './components/shipment-listing/shipment-listing.component';
import { ShipmentBookingsComponent } from './components/shipment-bookings/shipment-bookings.component';
import { ShipmentTableComponent } from './components/shipment-table/shipment-table.component';
import { FirstColumnListingComponent } from './components/first-column-listing/first-column-listing.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';

import { NgbModule, NgbPopoverModule, NgbDropdownModule  } from '@ng-bootstrap/ng-bootstrap';
import { AttachmentBookingsComponent } from './components/attachment-bookings/attachment-bookings.component';
import { ActionButtonsComponent } from './components/action-buttons/action-buttons.component';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    PdfJsViewerModule,
    NgSelectModule,
    AgGridModule,
    NgbModule,
    NgbPopoverModule,
    NgbDropdownModule,
    ShipmentsRoutingModule,
  ],
  declarations: [ ShipmentsComponent, ShipmentListingComponent, ShipmentBookingsComponent, ShipmentTableComponent, FirstColumnListingComponent, AttachmentBookingsComponent, ActionButtonsComponent]
})

export class ShipmentsModule {

}
