import { ChangeDetectorRef, Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { Observable, of, forkJoin, map, take } from 'rxjs';
import { CellClickedEvent, ColDef, GridReadyEvent } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { HttpClient } from '@angular/common/http';
import { ShipmentService } from 'src/app/services/modules/shipments/shipment.service';
import { AuthService } from "src/app/services/auth/auth.service";
import { ApiService } from 'src/app/services/api/api.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from "@angular/router";
import { TabService } from 'src/app/services/components/tab/tab.service';


interface Category {
  id: number,
  name: string,
}
interface VehicleType {
  id: number,
  name: string,
}
interface Location {
  id: number,
  name: string,
}
interface Insurance {
  id: number,
  name: string,
}
@Component({
  selector: 'app-shipments',

  templateUrl: './shipments.component.html',
  styleUrls: ['./shipments.component.scss']
})

export class ShipmentsComponent {


  tabs: any = [];
  activeTab!: number;
  public fromDashboard = null;
  moduleName = 'shipments';


  constructor(
    private shipmentService: ShipmentService,
    private tabService: TabService,
    private authService: AuthService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.checkChangeView();

    this.checkFromDashboard();

    this.getTabs();

  }

  private checkFromDashboard(){


    this.route.queryParams.subscribe(params => {


      if(params['filter']){
        if (params['tab'] == "listings") {

          this.activeTab = 0;
          this.fromDashboard = params['tab'];
          this.updateCustomerActiveTab('listings');
        }
        if (params['tab'] == "bookings") {
          this.fromDashboard = params['tab'];
          this.activeTab = 1;
          this.updateCustomerActiveTab('bookings');
        }
      }


  });
  }

  checkChangeView() {
    this.authService.getUser().subscribe((res: any) => {
      const customer = res.data;
      if (customer.my_listings_redirect != null && customer.my_listings_redirect == "1") {
        this.myListingsRedirect();
      }
    });
  }

  myListingsRedirect() {
    window.location.href = environment.appUrl + '/customer/listings';1
  }

  switchView() {
    this.shipmentService.changeShipmentsView()
      .subscribe((res: any) => {
        if (res.status == 'success') {
          this.checkChangeView();
        }
      });
  }

  getTabs() {

    this.tabService.getTabs(this.moduleName)
      .pipe(take(1))
      .subscribe((res: any) => {
        this.tabs = res.data.sort((a: any, b: any) => a.position - b.position)
        this.fetchCustomerActiveTab();
      });
  }

  fetchCustomerActiveTab() {
    this.tabService.getCustomerTabs(this.moduleName)
      .pipe(take(1))
      .subscribe((res: any) => {

        let activeListing = this.tabs.find((tab: any) => tab.backend_name === res.data.active_tab)

        if (activeListing) {
          this.setActiveTab(activeListing)
        }
      });
  }

  updateCustomerActiveTab(tab: string) {
    const payload = {
      'tab': tab,
      'module': this.moduleName
    };
    this.tabService.updateCustomerTabs(payload)
      .pipe(take(1))
      .subscribe((res: any) => {
        this.fetchCustomerActiveTab();
      });
  }

  setActiveTab(obj: any) {
    this.cdr.detectChanges();

    this.activeTab = obj.position;
  }



}
