import { Injectable } from '@angular/core';
import { ApiService } from '../../api/api.service';
import { BehaviorSubject, map } from 'rxjs';
import { MenuItem } from 'src/app/components/sidebar/menu-item';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  protected endpoint?: string;

  activeTab: number = 0;

  private menuOptions: MenuItem[] = [];
  public showProdMenu = false;

  constructor(private apiService: ApiService) {
    this.endpoint = 'menu/sidebar';
  }

  getSidebar() {
    return this.apiService.get(`${this.endpoint}`).pipe(
      map((res: any) => {
        console.log('API Response:', res); // Debug the raw API response
        // Filter and transform logic
        const flatMenuData = res.data.filter(
          (menu: { environment: string }) =>
            !this.showProdMenu ||
            (this.showProdMenu && menu.environment === 'Prod')
        );
        const hierarchicalMenu = this.buildHierarchy(flatMenuData);
        return hierarchicalMenu;
      })
    );
  }

  setActiveTab() {
    const currentPage = window.location.pathname;
    const findTabIndex = (menu: MenuItem, index: number): number | null => {
      if (menu.route === currentPage || menu.route === currentPage.slice(1)) {
        return index;
      }
      for (const child of menu.children || []) {
        const childIndex = findTabIndex(child, index);
        if (childIndex !== null) {
          return childIndex;
        }
      }
      return null;
    };

    this.activeTab = this.menuOptions.findIndex(
      (menu, index) => findTabIndex(menu, index) !== null
    );
  }

  getActiveTab() {
    return this.activeTab;
  }

  private sortByMenuOrder(arr: MenuItem[]): MenuItem[] {
    return arr.sort((a, b) => {
      const orderA = parseInt(a.order as any, 10);
      const orderB = parseInt(b.order as any, 10);

      if (isNaN(orderA) && isNaN(orderB)) {
        return 0; // Both orders are not valid integers
      } else if (isNaN(orderA)) {
        return 1; // 'a' is not a valid integer, so it comes after 'b'
      } else if (isNaN(orderB)) {
        return -1; // 'b' is not a valid integer, so it comes after 'a'
      }

      return orderA - orderB; // Compare the integers
    });
  }

  private buildHierarchy(flatData: any[]): any[] {
    const menuMap: { [key: string]: any } = {};
    const rootMenu: any[] = [];

    flatData.forEach((item) => {
      menuMap[item.id] = { ...item, children: [] };
    });

    flatData.forEach((item) => {
      const currentItem = menuMap[item.id];

      if (String(item.parent_id) === '0') {
        rootMenu.push(currentItem);
      } else {
        const parentItem = menuMap[String(item.parent_id)];
        if (parentItem) {
          parentItem.children.push(currentItem);
        }
      }
    });

    return rootMenu;
  }
}
