<div class="modal-header">
    <h4 class="modal-title" id="modal-title">
        <i class="{{headerIcon}} {{headerIconStyle}}"></i>{{headerTitle}}
    </h4>
    <button
        type="button"
        class="btn-close"
        aria-describedby="modal-title"
        (click)="cancel()"
    ></button>
</div>
<div class="modal-body">
    <p>

        <span [innerHTML]="subtitleText"></span> <strong class="text-info" *ngIf="whatToDelete">"{{whatToDelete}}"</strong> ?
    </p>
    <p>
        {{bodyText1}}
        <span class="text-danger">{{bodyText2}}</span>
    </p>
</div>
<div class="modal-footer">
    <button type="button" class="btn {{btnStyle2}}" (click)="cancel()">{{btnText2}}</button>
    <button type="button" class="btn {{btnStyle}}" (click)="confirm()">{{btnText}}</button>
</div>
