import { Component, ViewChild, ElementRef } from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {FeedbackService} from "../../services/components/feedback/feedback.service";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-feedbackform',
  templateUrl: './feedbackform.component.html',
  styleUrls: ['./feedbackform.component.scss']
})
export class FeedbackformComponent {
  @ViewChild('feedbackTextArea') feedbackTextArea!: ElementRef;
  @ViewChild('mayContact') mayContact!: ElementRef;

  constructor(public modalService: NgbActiveModal, private feedbackService: FeedbackService) {
  }

  ngOnInit() {
  }


  submitFeedback() {
    const payloads = {
      feedback: this.feedbackTextArea.nativeElement.value,
      mayContact: this.mayContact.nativeElement.checked
    };

    if(payloads.feedback === '' || payloads.feedback.length < 10) {
      Swal.fire(
              'Error!',
              'Feedback must be at least 10 characters long.',
              'error'
            );
            return;
    }

    this.feedbackService.saveFeedback(payloads).subscribe((res) => {
      Swal.fire(
        'Submitted!',
        'Your feedback has been submitted.',
        'success'
      ).then((result) => {
        if (result.isConfirmed) {
          this.modalService.dismiss();
        }
      });
    }, (error) => {
      Swal.fire(
        'Error!',
        'There was an error submitting your feedback.',
        'error'
      ).then((result) => {
        if (result.isConfirmed) {
          this.modalService.dismiss();
        }
      });
    });
  }
}
