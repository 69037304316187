<div class="d-flex flex-column align-items-start align-self-stretch gap-4 p-3 w-100"
    style="background: var(--neutral-white, #FFF);">

    <div class="w-100">
        <ul class="nav nav-tabs d-flex justify-content-between flex-nowrap">
            <div style="display: flex;"> <li class="nav-item" *ngFor="let tab of tabs">
                <span class="nav-link" style="cursor: pointer;" [ngClass]="{'active': tab.position === activeTab}"
                    (click)="updateCustomerActiveTab(tab.backend_name)">{{ tab.name }}</span>
            </li></div>

            <li class="nav-item">
                <span class="nav-link" style="cursor: pointer;"
                (click)="switchView()"><u>Switch to Classic View</u></span>
            </li>
        </ul>
    </div>
    <div [ngSwitch]="activeTab" class="w-100">
        <div *ngSwitchCase="0"> <!-- Content for the 'Listings' tab -->
            <app-shipment-listing ></app-shipment-listing>
        </div>
        <div *ngSwitchCase="1"> <!-- Content for the 'Bookings' tab -->
          <app-shipment-bookings ></app-shipment-bookings>
      </div>
    </div>
</div>
