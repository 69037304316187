import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, timeout } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private domain?: string;


  constructor(private httpClient: HttpClient) {
    this.domain = `${environment.domain}/${environment.apiVersion}`
  }

  protected _getHeaders(skipInterceptor: boolean = false): any {
    const headers: { [key: string]: string } = {
      // 'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': StorageService.getCookie('AUTH')
    };

    if (skipInterceptor) {

      // Add the custom header to skip the interceptor
      headers['X-Skip-Interceptor'] = 'true';
    }

    return headers;
  }

  get(endpoint: string, payloads: any = {}, skipInterceptor: boolean = false): Observable<any[]> {
    return this.httpClient.get<any>(`${this.domain}/${endpoint}`, {
      headers: this._getHeaders(skipInterceptor),
      params: payloads
    },

    );
  }

  getById(endpoint: string, payloads: any = {}, id: number, skipInterceptor: boolean = false): Observable<any[]> {
    return this.httpClient.get<any>(`${this.domain}/${endpoint}/${id}`, {
      headers: this._getHeaders(skipInterceptor),
      params: payloads
    });
  }

  post(endpoint: string, payloads: any, skipInterceptor: boolean = false) {
    return this.httpClient.post<any>(`${this.domain}/${endpoint}`, payloads, {
      headers: this._getHeaders(skipInterceptor)
    })
  }

  put(endpoint: string, payloads: any, skipInterceptor: boolean = false): Observable<any[]> {
    return this.httpClient.put<any>(`${this.domain}/${endpoint}`, payloads, {
      headers: this._getHeaders(skipInterceptor)
    })
  }

  delete(endpoint: string, payloads: any = {}, skipInterceptor: boolean = false) {
    return this.httpClient.delete<any>(`${this.domain}/${endpoint}`, {
      headers: this._getHeaders(skipInterceptor),
      body: payloads
    })
  }

  postProgressive(endpoint: string, payloads: any, skipInterceptor: boolean = false) {
    return this.httpClient.post<any>(`${this.domain}/${endpoint}`, payloads, {
      headers: this._getHeaders(skipInterceptor),
      reportProgress: true,
      observe: 'events'
    })
  }

  uploadFile(endpoint: string, formData: FormData): Observable<any> {
    return this.httpClient.post<any>(`${this.domain}/${endpoint}`, formData, {
      headers: new HttpHeaders({
        'Authorization': StorageService.getCookie('AUTH'),
        'Accept': 'application/json'
        // No 'Content-Type' header here; HttpClient will set it automatically for FormData
      })
    });
  }

  //temporary only while there is performance issue in monolith
  // createListingPost(endpoint: string, payloads: any, timeout: number, skipInterceptor: boolean = false) {
  //   return this.httpClient.post<any>(`${this.domain}/${endpoint}`, payloads, {
  //     headers: this._getHeaders(skipInterceptor)
  //   })
  // }
  createListingPost(endpoint: string, payloads: any, timeoutDuration: number, skipInterceptor: boolean = false): Observable<any> {
    // const request = this.httpClient.post<any>(`${this.domain}/${endpoint}`, payloads, {
    //   headers: this._getHeaders(skipInterceptor)
    // });
    //
    // return request.pipe(timeout(timeoutDuration));
    return this.httpClient.post<any>(`${this.domain}/${endpoint}`, payloads, {
      headers: this._getHeaders(skipInterceptor)
    }).pipe(
      timeout(timeoutDuration)
    );
  }
}
