<header class="navbar sticky-top bg-primary navbar-expand-lg flex-md-nowrap p-0 shadow">
    <a class="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6" href="#">
        <img src="assets/logo/truckit.svg" alt="image">
    </a>
    <span class="badge badge-pill badge-primary beta-styling d-none d-lg-block">Business BETA</span>
    <ul class="navbar-nav d-flex flex-row d-md-none align-items-center gap-3">
        <li class="nav-item text-nowrap">
            <div ngbDropdown class="d-inline-block " display="dynamic" placement="bottom-end">
                <div role="button" id="notificationDropDownMobileView" ngbDropdownToggle
                    class="d-flex align-content-center notification-toggle text-white">
                    <i class="bi bi-bell text-center"></i>
                </div>
                <div ngbDropdownMenu aria-labelledby="notificationDropDownMobileView">
                    <div class="d-flex flex-column align-items-start overflow-y-scroll"
                        style="min-width: 300px; max-width: 300px; max-height: 550px;">
                        <div *ngFor="let n of notificationList" class="d-flex flex-column border-bottom p-3 gap-2"
                            ngbDropdownItem>
                            <div class="d-flex flex-row gap-3 align-items-start">
                                <div id="notificationIcon" class="d-flex rounded-circle">
                                    <i class="text-center bi bi-info-circle-fill fs-3"
                                        style="width: 2rem !important; height: 2rem !important;"></i>
                                </div>
                                <div class="d-flex flex-column gap-2">
                                  <div class="text-wrap" [innerHTML]="n.message"></div>
                                  <div class="text-muted">{{ getTimeAgo(n.created_at) }}</div>
                                  <div class="d-flex flex-row gap-2">
                                      <a href="{{n.target_url}}" class="btn btn-sm" target="_blank">View</a>


                                      <button class="btn btn-sm" style="color: #F05A29;">Dismiss</button>
                                  </div>
                                </div>
                            </div>

                        </div>
                        <div *ngIf="notificationList.length > 0" class="w-100">

                            <div class="d-grid gap-2">
                                <button class="btn btn-sm btn-block" (click)="fetchNotifications()">Load
                                    More</button>
                            </div>
                        </div>

                        <div *ngIf="notificationList.length === 0"
                            class="d-flex justify-content-center align-items-center text-center my-3"
                            style="height: 100px;">
                            No notifications available
                        </div>
                    </div>
                </div>
            </div>
        </li>
        <li class="nav-item text-nowrap">
            <button class="nav-link px-3 text-white" type="button" data-bs-toggle="offcanvas"
                data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false"
                aria-label="Toggle navigation">
                <i class="bi bi-list"></i>
            </button>
        </li>

    </ul>
    <div class="collapse navbar-collapse p-4" id="navbarText">
        <ul class="navbar-nav gap-5 me-auto mb-2 mb-lg-0">
            <ng-container *ngFor="let navItem of topLevelMenuItems">
                <!-- For items without children -->
                <li *ngIf="!navItem.childrenColumnOne.length && !navItem.childrenColumnTwo.length" class="nav-item">
                    <a class="nav-link text-white lh-base" href="{{ navItem.item.route }}"
                        (click)="onMenuItemClick(navItem.item, $event)" target="_blank">{{ navItem.item.name }}</a>
                </li>

                <!-- For the dropdown menu (Create a shipment) -->
                <li *ngIf="navItem.childrenColumnOne && navItem.childrenColumnOne.length > 0" class="nav-item"
                    ngbDropdown #csDropdown="ngbDropdown" display="dynamic"
                    (mouseenter)="$event.stopPropagation(); csDropdown.open()"
                    (mouseleave)="$event.stopPropagation(); csDropdown.close()">
                    <button type="button" class="btn btn-outline-light" ngbDropdownToggle>{{ navItem.item.name
                        }}</button>
                    <div ngbDropdownMenu aria-labelledby="createShipmentDropDown">
                        <div class="d-flex flex-row align-items-center">
                            <div class="d-flex flex-column align-items-start  p-1 gap-2" style="min-width: 310px;">
                                <a [href]="cs.route" target="_blank" *ngFor="let cs of navItem.childrenColumnOne"
                                    class="px-1 csDropDowmItem" role="button" ngbDropdownItem>
                                    <div class="d-flex flex-row align-items-center justify-content-between p-2"
                                        *ngIf="cs.index_column == 1">
                                        <div class="d-flex flex-row align-items-center gap-2">
                                            <span class=""><img [src]="cs.icon" alt=""></span>
                                            <span class="fst-normal fs-6 lh-base">{{ cs.name }}</span>
                                        </div>

                                        <span class=""><img src="assets/icons/fi_arrow-right.svg"
                                                class="csDropDowmItemEndIcon d-none" alt=""></span>

                                    </div>
                                </a>
                            </div>
                            <div class="d-flex flex-column align-items-start  p-1 gap-2" style="min-width: 310px;">
                                <a [href]="cs.route" target="_blank" *ngFor="let cs of navItem.childrenColumnTwo"
                                    class="px-1 csDropDowmItem" role="button" ngbDropdownItem>
                                    <div class="d-flex flex-row align-items-center justify-content-between p-2"
                                        *ngIf="cs.index_column == 2">
                                        <div class="d-flex flex-row align-items-center gap-2">
                                            <span class=""><img [src]="cs.icon" alt=""></span>
                                            <span class="fst-normal fs-6 lh-base">{{ cs.name }}</span>
                                        </div>

                                        <span class=""><img src="assets/icons/fi_arrow-right.svg"
                                                class="csDropDowmItemEndIcon d-none" alt=""></span>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </li>
            </ng-container>
        </ul>

        <div class="nav-right d-flex flex-row align-items-center gap-4">
            <div ngbDropdown>
                <div role="button" id="notificationBell" class="d-flex align-content-center rounded-circle p-2"
                    ngbDropdownToggle>
                    <i class="bi bi-bell text-center" style="width: 1.5rem !important; height: 1.5rem !important;"></i>
                </div>
                <div ngbDropdownMenu aria-labelledby="notificationBell" style="max-height: 37.5rem;"
                    class="dropdown-menu-end px-0 overflow-y-scroll">
                    <div class="d-flex flex-column align-items-start" style="width: 350px;">
                        <div *ngFor="let n of notificationList"
                            class="d-flex flex-column border-bottom py-3 pe-4 gap-2 w-full" ngbDropdownItem>
                            <div class="d-flex flex-row gap-3 align-items-start">
                                <div id="notificationIcon" class="d-flex rounded-circle">
                                    <i class="text-center bi bi-info-circle-fill fs-3"
                                        style="width: 2.5rem !important; height: 2.5rem !important;"></i>
                                </div>
                                <div class="d-flex flex-column gap-2">
                                  <div class="text-wrap" [innerHTML]="n.message"></div>
                                  <div class="text-muted">{{ getTimeAgo(n.created_at) }}</div>
                                  <div class="d-flex flex-row gap-2">
                                      <a href="{{n.target_url}}" class="btn btn-sm" target="_blank">View</a>


                                      <button class="btn btn-sm" style="color: #F05A29;">Dismiss</button>
                                  </div>
                                </div>

                            </div>



                        </div>
                        <div *ngIf="notificationList.length > 0" class="w-100">

                            <div class="d-grid gap-2">
                                <button class="btn btn-sm btn-block" (click)="fetchNotifications()">Load
                                    More</button>
                            </div>
                        </div>
                        <div *ngIf="notificationList.length === 0"
                            class="d-flex justify-content-center align-items-center text-center my-3"
                            style="height: 100px;">
                            No notifications available
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex flex-row align-items-center" role="button" id="accountDropdown" ngbDropdown
                ngbDropdownToggle>
                <div class="d-flex flex-row align-items-center gap-3">
                    <div class="d-flex flex-row align-items-center justify-content-center rounded-circle p-2 gap-2"
                        style="width: 3.5rem; height: 3.5rem;">
                        <span class="text-center text-white w-auto lh-1 fw-bold">
                            <img class="rounded-circle" style="width: 100%; height: 100%; object-fit: cover;"
                                src="https://truckit-profile-files.s3-ap-southeast-2.amazonaws.com/{{customerDetails?.profile_image}}" />
                        </span>

                    </div>
                    <div class="d-flex flex-column align-items-start">
                        <!-- <div class="text-white fw-bold">{{ customerDetails?.firstname }} {{
                            customerDetails?.lastname }}</div> -->
                        <div class="text-white fw-bold d-inline-block text-truncate" style="width: 150px !important;">{{ customerDetails?.firstname }}</div>
                        <div class="text-white fw-lighter lh-sm">{{ customerDetails?.business_name }}</div>
                    </div>
                </div>

                <div ngbDropdownMenu aria-labelledby="accountDropdown">
                    <div class="d-flex flex-column gap-2">
                        <a href="https://www.truckit.net/customer/account" target="_blank"
                            class="d-flex justify-content-start gap-1" ngbDropdownItem>
                            <div><i class="bi bi-tools"></i></div>
                            <div>Account Settings</div>
                        </a>
                        <a href="https://truckit.net/logout" class="d-flex justify-content-start gap-1" ngbDropdownItem>
                            <div><i class="bi bi-box-arrow-right"></i></div>
                            <div>Log Out</div>
                        </a>
                    </div>
                </div>

            </div>
        </div>
    </div>
</header>
