<div class="container" style="max-width: 1000px;">
  <div class="d-flex flex-lg-row flex-md-column flex-sm-column flex-column gap-3">
    <div class="col-lg-8 col-md-12">
      <div class="d-flex flex-column h-100">

        <div class="d-flex flex-row pb-3 col-12">
          <div class="card p-3 border-1 rounded-2 dashboard-card-secondary w-100">
            <div class="card-title fw-bold fs-4">
              Ready to ship
            </div>
            <div class="card-body px-0">
              Create a listing and/or invite providers to quote on it.
            </div>
            <div class="d-grid col-lg-6 col-md-12 col-sm-12 col-12">
              <button class="btn btn-secondary text-white"
              (click)="redirectTo('/instant-quote')">Create a
                listing</button>
            </div>
          </div>
        </div>

        <div class="d-flex flex-wrap flex-row pb-3 col-12">
          <div class="col-sm-6 col-md-6 col-lg-4 col-12" *ngFor="let card of keyValues" (click)="redirectToShipments(card)">
            <div class="card m-2 py-3 text-center 2-100" style="cursor: pointer;">
              <div class="card-title fw-bold fs-6 text-capitalize text-muted">
                {{card.title}}
              </div>
              <div class="card-body fs-3 fw-bolder px-0 pt-0 read-on">
                {{card.value}}
              </div>
            </div>
          </div>
          <!-- <div class="col-sm-6 col-md-6 col-lg-4 col-12">
          <div class="card m-2 py-3 text-center 2-100" style="cursor: pointer;">
            <div class="card-title fw-bold fs-6 text-capitalize text-muted">
              Welcome video
            </div>
            <div class="card-body fs-3 fw-bolder px-0 pt-0 read-on">
              <i class="fas fa-play"></i>
          </div>
          </div>
        </div> -->
        </div>
      </div>
    </div>

    <div class="col-lg-4 col-md-12" style="height: 300px !important;">
      <div class="card p-3 border-1 rounded-2 w-100">
        <div class="card-title fs-5 fw-bold">
          Got complex freight?
        </div>
        <div class="card-body mx-0" >
          Speak to one of our account managers to settle your shipping problems.
        </div>
        <div class="d-grid col-12">
          <button class="btn btn-secondary text-white" (click)="this.getHelp()">
            Talk to an expert
          </button>
        </div>
      </div>
    </div>
  </div>
</div>