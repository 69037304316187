import { ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { ChildComponent } from '../child/child.component';
import { SidebarService } from '../../services/components/sidebar/sidebar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  menuOptions: any[] = [];

  collapseMenu: boolean = false;

  public showProdMenu = false;
  mainAppBaseURL: string = '';

  constructor(
    private sidebarService: SidebarService, 
    private router: Router
    ) {
      this.mainAppBaseURL = environment.appUrl;
    }

  

  ngOnInit(): void {
    this.sidebarService.getSidebar().subscribe((menuOptions: any[]) => {
      this.menuOptions = menuOptions;
    }).add(() => {
      this.setActiveTab();
    });
  }

  get _logoutURL(){
    return `${this.mainAppBaseURL}/logout`
  }

  toggleSideBar() {
    this.collapseMenu = !this.collapseMenu;
  }

  setActiveTab() {
    this.sidebarService.setActiveTab();
    // this.currentActiveTab = this.sidebarService.getActiveTab();
  }

  getCurrentActiveTab(){
    return this.sidebarService.getActiveTab();
  }

  private sortByMenuOrder(arr: { order: string }[]): { order: string }[] {
    return arr.sort((a, b) => {
        const orderA = parseInt(a.order, 10);
        const orderB = parseInt(b.order, 10);

        if (isNaN(orderA) && isNaN(orderB)) {
            return 0; // Both orders are not valid integers
        } else if (isNaN(orderA)) {
            return 1; // 'a' is not a valid integer, so it comes after 'b'
        } else if (isNaN(orderB)) {
            return -1; // 'b' is not a valid integer, so it comes after 'a'
        }

        return orderA - orderB; // Compare the integers
    });
}

  public navigate(route: string, index: number) {
    // Check if the route is a full URL (contains 'http' or 'https')
    if (route.startsWith('http') || route.startsWith('https')) {
      // It's a full URL, perform an external redirection
      window.location.href = route;
    } else {
      // It's a regular Angular route, navigate within the app
      this.router.navigate([route]).finally(() => {
        this.setActiveTab();
      });
      
    }
  }
}
