<div class="container-fluid px-0">
  <div class="d-flex flex-md-row flex-column align-content-center pb-3">
    <div class="d-lg-flex flex-row gap-4 align-items-center col-6">
      <div class="d-flex gap-2 align-items-center">
        <div class="text-muted col-auto">Filter by</div>
        <ng-select *ngIf="showSelect" [multiple]="true" [items]="statuses" groupBy="selectedAllGroup"
          [selectableGroup]="true" bindLabel="name" bindValue="value" placeholder="Status"
          [compareWith]="compareAccounts" [(ngModel)]="selectedStatus" [closeOnSelect]="false"
          (change)="onSelectionChange()" style="max-width: 520px;">
          <!-- first checkbox select from the dropdown -->
          <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" /> Select All
          </ng-template>

          <!-- Selected statues -->
          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div class="ng-value" *ngFor="let item of items | slice:0:2">

              <span class="ng-value-label">{{statusLabelName(item)}} </span>
              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>

            </div>
            <div class="ng-value" *ngIf="items.length > 2">
              <span class="ng-value-label">{{items.length - 2}} more...</span>
            </div>
          </ng-template>
        </ng-select>
      </div>
    </div>

    <!-- entire save view div -->
    <div class="d-flex flex-row justify-content-end align-items-center col-6 gap-3" *ngIf="showViewFilterSection">


      <div *ngIf="showActionViewSearchButton" class="d-flex flex-row justify-content-end">

        <!-- plus button -->
        <button class="btn btn-dark text-white mr-3" (click)="addViewFilter()" *ngIf="!showAddViewFilterButton"><i
            class="bi bi-plus-lg"></i></button>

        <!-- cross button -->
        <button class="btn btn-dark text-white" (click)="hideAddViewFilter()" *ngIf="showAddViewFilterButton"><i
            class="bi bi-x-lg"></i></button>


      </div>
      <div class="input-group d-flex flex-row justify-content-end" style="width: 403px;">

        <!-- saved view text input -->
        <input type="text" *ngIf="!this.showViewFilterSelectInput" class="form-control rounded-end-0"
          style="width: 250px;" [(ngModel)]="viewFilterInput" placeholder="Save search name" maxlength="16">

        <!-- saved view dropdown -->
        <ng-select *ngIf="this.showViewFilterSelectInput && this.saveViewArray.length > 0" placeholder="Select a View"
          [(ngModel)]="this.selectedView" (ngModelChange)="selectViewFilterEvent();" placeholder="Select a Saved View"
          class="rounded-end-0" [closeOnSelect]="true" style="width: 250px;">
          <ng-option *ngFor="let item of saveViewArray" [value]="item._id">{{ item.name }}</ng-option>
        </ng-select>


        <div class="input-group-append">

          <!-- saved view button -->
          <button class="btn btn-dark text-white rounded-start-0" (click)="saveViewFilter()"
            *ngIf="showAddViewFilterButton">Save View</button>

          <!-- remove view button (trash)-->
          <button class="btn btn-dark text-white rounded-start-0" *ngIf="showViewFilterRemoveButton"
            (click)="confirmDialog()"><i class="bi bi-trash"></i></button>
        </div>
      </div>

      <!-- clear filter button -->
      <button class="btn btn-dark text-white" *ngIf="showClearFilter" (click)="clearFilter()">Clear Filters</button>
    </div>
  </div>

  <div class="row" *ngIf="this.customerId != null">

    <ag-grid-angular style="min-height: 400px; height: 800px;" class="ag-theme-alpine w-100"
      [suppressRowClickSelection]="true" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [sideBar]="sideBar"
      [paginationPageSize]="paginationPageSize" [paginationPageSizeSelector]="paginationPageSizeSelector"
      [pagination]="true" [cacheBlockSize]="cacheBlockSize" [rowSelection]="'multiple'" [animateRows]="true"
      [rowData]="rowData" (dragStopped)="eventHandlingListener($event)" (filterChanged)="eventHandlingListener($event)"
      (columnVisible)="onColumnVisible($event)" (gridReady)="onGridReady($event)"></ag-grid-angular>
  </div>
</div>
