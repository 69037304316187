<div class="d-flex flex-row gap-3 align-items-center">
    <!-- <input type="checkbox" name="" id=""> -->
    <i style="cursor: pointer; overflow: hidden;" class="bi bi-eye"
    popoverClass="my-custom-class" (click)="getCustomerListingDetails(listing_id)" [ngbPopover]="popContent"
         placement="right-top" container="body"></i>
</div>

<ng-template #popContent>
  <div class="d-felx flex-column">
      <div class="text-uppercase fw-bold text-secondary">Item Details</div>
      <div *ngIf="isLoading">Loading...</div>
      <div [innerHtml]="quickView" ></div>

  </div>
</ng-template>

