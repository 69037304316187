import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-action-buttons',
  templateUrl: './action-buttons.component.html',
  styleUrls: ['./action-buttons.component.scss']
})
export class ActionButtonsComponent {

  private params: any;
  public listing_id: any;
  public listing_category: any;

  redirectCopyListing(){
    const redirectUrl = `${environment.appUrl}/get-a-quote/${this.listing_category}/${this.listing_id}/similar`
    window.open(redirectUrl, '_blank');
  }


   // gets called once before the renderer is used
   agInit(params: ICellRendererParams): void {
    this.params = params;
    this.listing_id = this.params.data.listing_id;
    this.listing_category = this.params.data.category_url_key;

  }
  // gets called whenever the cell refreshes
  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    this.listing_id = this.params.data.listing_id;
    this.listing_category = this.params.data.category_url_key;
    return true;
  }
}
