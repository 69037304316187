import { Injectable } from '@angular/core';
import {ApiService} from "../../api/api.service";

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  protected endpoint?: string

  constructor(private apiService: ApiService) {
    this.endpoint = 'customer/feedback'
  }

  saveFeedback(payloads: any){
    return this.apiService.post(`${this.endpoint}`, payloads)
  }

}
