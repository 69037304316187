<div class="d-flex flex-row gap-3 align-items-center">
  <button class="btn btn-sm btn-light" [ngbPopover]="popContent" placement="bottom-right" container="body"><i
      class="bi bi-three-dots-vertical"></i></button>
</div>


<ng-template #popContent>
  <div class="flex flex-column">
    <button type="button" (click)="redirectCopyListing()"
    class="btn btn-sm btn-secondary text-white"
    >
      <i class="bi bi-copy"></i>&nbsp;List Similar</button>
  </div>
</ng-template>
