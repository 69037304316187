import {Component, OnInit} from '@angular/core';
import { ParentComponent } from 'src/app/components/parent/parent.component';
import {FinancialsService} from "../../../../services/modules/financials/financials.service";
import {DashboardService} from "../../../../services/modules/dashboards/dashboard.service";
import {Router} from "@angular/router";
import { SidebarService } from 'src/app/services/components/sidebar/sidebar.service';

interface Action {
  id: number;
  title: string;
  desc: string;
  disabled?: boolean;
  action_name: string;
  action_url: string;
}

interface KeyValues {
  title: string;
  value: number;
}

interface ValuesResponse {
  status: number;
  values: KeyValues[];
}
interface Help {
  title: string;
  desc: string;
  action_name: string;
}


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends ParentComponent implements OnInit {

  constructor(
    private dashboardService: DashboardService,
    private router: Router,
    private sidebarService: SidebarService
    ) {
    super();
  }

  public actions: Action[] = [];
  public keyValues: KeyValues[] = [];

  ngOnInit() {
    this.setActions();
    this.getKeyValues();

  }
  public help: Help = {
    title: 'Got complex freight?',
    desc: 'Speak to one our account managers to settle your shipping problems',
    action_name: 'talk to an expert',
};

public getHelp() {
  window.open('https://www.truckit.net/get-a-quote/projects', '_blank');
}

  public setActions(): void {
    const shipAction: Action = {
      id: 1,
      title: 'Ready to ship',
      desc: 'Create a listing and/or invite providers to quote on it',
      action_name: 'Create a shipment',
      action_url: '/instant-quote',
      disabled: false
    };

    this.actions.push(shipAction);

  }

  private getKeyValues() {
    this.dashboardService.getKeyValues().subscribe(response => {
      const valuesResponse: ValuesResponse = response;
      if (valuesResponse.status === 200) {
        this.keyValues = valuesResponse.values;
      } else {

      }
    }, error => {

    });
  }

  redirectTo(url: string): void {
    this.router.navigateByUrl(url).finally(() => {
      this.sidebarService.setActiveTab();
    });
    
  }

  redirectToShipments(card: any): void {
    let tab: string;
    let filter: string;

    // Determine the tab and filter based on the card's title
    switch (card.title.toLowerCase()) {
      case 'active listings':
        tab = 'listings';
        filter = 'active';
        break;
      case 'booked listings':
        tab = 'listings';
        filter = 'booked';
        break;
      case 'jobs in transit':
        tab = 'bookings';
        filter = 'in-transit';
        break;
      case 'jobs completed':
        tab = 'bookings';
        filter = 'complete';
        break;
      default:
        // Set defaults or handle unknown cases
        tab = 'listings'; // Replace with your actual default tab if necessary
        filter = 'all'; // Replace with your actual default filter if necessary
    }

    let encodedFilter = encodeURIComponent(filter);

    // Navigate with both tab and filter as query parameters
    this.router.navigate(
      ['/shipments'], 
      { queryParams: 
        { tab: tab, filter: encodedFilter } 
      }).finally(() => {
        this.sidebarService.setActiveTab();
      });
    
  }
}
