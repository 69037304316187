<!-- <div class="sidebar" [class.collapsed]="collapseMenu">
    <div class="sidebar-content d-flex flex-column align-items-start align-self-stretch gap-3 py-4 px-2">
        <ng-container *ngFor="let m of menuOptions; let i = index">
            <a *ngIf="m.parent_id == 0" class="sidebar-item text-decoration-none"
            [ngClass]="{'active': i === activeTab}"
            (click)="this.navigate(m.route, i)"
             role="button">
                <span class="sidebar-item-icon"><img [src]="m.icon" alt=""></span>
                <span class="sidebar-item-title" [ngClass]="{'active': i === activeTab}">{{m.title}}</span>
            </a>
        </ng-container>
    </div>
    <div class="toggle" role="button" (click)="collapseMenu=!collapseMenu">
        <i class="bi bi-arrow-left-right"></i>
    </div>

</div> -->

<!-- <nav class="sidebar">
    <header>
        <div class="image-logo bg-primary">
            <span class="image"><img src="assets/logo/truckit.svg" alt="image"></span>
        </div>
    </header>
    <div class="sidebar-content" >
        <div class="w-100" *ngFor="let m of menuOptions; let i = index">
            <a *ngIf="m.parent_id == 0" class="sidebar-item text-decoration-none"
            [ngClass]="{'active': i === activeTab}"
            (click)="this.navigate(m.route, i)"
            role="button">
                <span class="sidebar-item-icon"><img [src]="m.icon" alt=""></span>
                <span class="sidebar-item-title" [ngClass]="{'active': i === activeTab}">{{m.title}}</span>
            </a>
        </div>
    </div>
</nav> -->

<div class="sidebar">
    <div class="offcanvas-md offcanvas-end " tabindex="-1" id="sidebarMenu" aria-labelledby="sidebarMenuLabel">
      <div class="offcanvas-header">
        <div class="bg-primary py-3 px-5" id="sidebarMenuLabel">
          <img src="https://static.truckit.net/t4b/logos/logo-light.svg" alt="image">
        </div>
        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#sidebarMenu" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body d-md-flex flex-column p-3 pt-lg-3 overflow-y-auto">
        <ul class="nav flex-column sidebar-content">
          <li class="nav-item" *ngFor="let m of menuOptions; let i = index">
            <a *ngIf="m.parent_id == 0" class="sidebar-item text-decoration-none" data-bs-dismiss="offcanvas" data-bs-target="#sidebarMenu"
            [ngClass]="{'active': i === getCurrentActiveTab()}"
            (click)="this.navigate(m.route, i)"
            role="button">
                <span class="sidebar-item-icon"><img [src]="m.icon" alt=""></span>
                <span class="sidebar-item-title" [ngClass]="{'active': i === getCurrentActiveTab()}">{{m.title}}</span>
            </a>
          </li>
        </ul>

        <hr class="my-3">

        <ul class="nav flex-column sidebar-content">
          <li class="nav-item" >
            <a [href]="_logoutURL" class="sidebar-item text-decoration-none">
                <span class="sidebar-item-icon"><i class="bi bi-box-arrow-left fs-5"></i></span>
                <span class="sidebar-item-title">Logout</span>
            </a>
          </li>
        </ul>

        <!-- <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-body-secondary text-uppercase">
          <span>Saved reports</span>
          <a class="link-secondary" href="#" aria-label="Add a new report">
            <svg class="bi"><use xlink:href="#plus-circle"/></svg>
          </a>
        </h6> -->
        <!-- <ul class="nav flex-column mb-auto">
          <li class="nav-item">
            <a class="nav-link d-flex align-items-center gap-2" href="#">
              <svg class="bi"><use xlink:href="#file-earmark-text"/></svg>
              Current month
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link d-flex align-items-center gap-2" href="#">
              <svg class="bi"><use xlink:href="#file-earmark-text"/></svg>
              Last quarter
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link d-flex align-items-center gap-2" href="#">
              <svg class="bi"><use xlink:href="#file-earmark-text"/></svg>
              Social engagement
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link d-flex align-items-center gap-2" href="#">
              <svg class="bi"><use xlink:href="#file-earmark-text"/></svg>
              Year-end sale
            </a>
          </li>
        </ul>

        <hr class="my-3">

        <ul class="nav flex-column mb-auto">
          <li class="nav-item">
            <a class="nav-link d-flex align-items-center gap-2" href="#">
              <svg class="bi"><use xlink:href="#gear-wide-connected"/></svg>
              Settings
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link d-flex align-items-center gap-2" href="#">
              <svg class="bi"><use xlink:href="#door-closed"/></svg>
              Sign out
            </a>
          </li>
        </ul> -->
      </div>
    </div>
  </div>
