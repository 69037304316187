import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { SpinnerService } from 'src/app/components/spinner/spinner.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor(private spinnerService: SpinnerService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (request.headers.has('X-Skip-Interceptor')) {

      return next.handle(request);
    }

    this.spinnerService.requestStarted();

    return this.handlerRequest(next, request);
    
  }

  handlerRequest(next: any, request: any) {
    return next.handle(request)
      .pipe(
        tap({
          next: (event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              this.spinnerService.requestEnded();
            }
          }, 
          error: (error) => {
            if (error instanceof HttpErrorResponse) {
              if (error.status == 401) location.reload();
              this.spinnerService.requestEnded();
            }
          }
        })
      )
  }
}
