import { Component, Injectable, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
@Injectable()
export class ConfirmDialogComponent {
  @Input({required: true}) headerTitle!: string;
  @Input({required: true}) headerIconStyle!: string;
  @Input({required: true}) headerIcon!: string;
  @Input() subtitleText: string = 'Are you sure you want to delete saved view: ';
  @Input() bodyText1: string = '';
  @Input() bodyText2: string = '';
  @Input({required: true}) btnStyle!: string;
  @Input() btnStyle2: string = 'btn-outline-secondary';
  @Input() btnText: string = 'Ok';
  @Input() btnText2: string = 'Cancel';
  @Input() whatToDelete = 'selected item';

  constructor(public activeModal: NgbActiveModal) {}

  confirm() {
    this.activeModal.close('confirm');
  }

  cancel() {
    this.activeModal.dismiss('cancel');
  }
}
