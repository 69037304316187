import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { CellClickedEvent, ColDef, GridReadyEvent } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-shipment-table',
  templateUrl: './shipment-table.component.html',
  styleUrls: ['./shipment-table.component.scss']
})
export class ShipmentTableComponent implements OnChanges {
  @Input() columnDefs!: ColDef[];
  @Input() defaultColDef!: ColDef;
  @Input() rowData!: any[];
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  public paginationPageSize = 10;
  public cacheBlockSize = 10;
  
  public rowData$!: any[];
  constructor(){}

  ngOnChanges(changes: SimpleChanges): void {
    this.rowData$ = this.rowData
  //   //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
  //   //Add '${implements OnChanges}' to the class.
    
  }

  onGridReady(params: GridReadyEvent) {
    this.rowData$ = this.rowData;
  }

  onCellClicked(e: CellClickedEvent): void {
    // console.log('cellClicked', e);
  }
}
