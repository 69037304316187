import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public static user: any = null;

  protected endpoint?: string

  constructor(private apiService: ApiService) {

    this.getUser().subscribe((user: any) => {

      if(!(user.data.is_t4b_customer == "1" || user.data.is_t4b_customer == 1)){
        window.location.href = environment.appUrl;
      }
    });
  }

  public getUser(): any {
    return this.apiService.get('customer', {}, true);
  }

}
