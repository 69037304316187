export const environment = {
    production: false,
    domain: 'https://api-business-dev.truckit.net/api',
    apiVersion: 'v1',
    // auth_url: 'https://auth-sandbox.truckit.net/auth?client_id=e136f653-4ecc-4908-8572-cb629904cf48',
    auth_url: 'https://auth-sandbox.truckit.net/auth?client_id=e136f653-4ecc-4908-8572-cb629904cf48',
    auth_callback: 'https://auth-sandbox.truckit.net/auth',
    auth_failed: 'https://dev1.truckit.net/login',
    auth_validate: 'https://auth-sandbox.truckit.net/auth/validate?client_id=e136f653-4ecc-4908-8572-cb629904cf48',
    // auth_validate: 'https://auth-sandbox.truckit.net/auth/validate?client_id=e136f653-4ecc-4908-8572-cb629904cf48',
    appUrl: 'https://dev1.truckit.net',
};


