import { Injectable } from '@angular/core';
import { ApiService } from '../../api/api.service';
import { environment } from 'src/environments/environment';
import { FirstColumnListingComponent } from 'src/app/modules/shipments/components/first-column-listing/first-column-listing.component';
import { lastValueFrom } from 'rxjs';
import * as moment from 'moment';
import { IDateFilterParams } from 'ag-grid-community';
import { format } from 'date-fns';
import { AttachmentBookingsComponent } from 'src/app/modules/shipments/components/attachment-bookings/attachment-bookings.component';
import { ActionButtonsComponent } from 'src/app/modules/shipments/components/action-buttons/action-buttons.component';
import { CustomBadgeRendererComponent } from 'src/app/components/custom-badge-renderer/custom-badge-renderer.component';
import { ProviderRendererComponent } from 'src/app/modules/shipments/components/provider-renderer/provider-renderer.component';
import { DateStatusComponent } from 'src/app/components/date-status/date-status.component';

@Injectable({
  providedIn: 'root'
})
export class ShipmentService {

  protected endpoint?: string
  protected customerDetails?: any

  private dateFormatPattern: RegExp = /^\d{2}\/\d{2}\/\d{4}$/;

  constructor(private apiService: ApiService) {
    this.endpoint = 'shipment'
  }

  // getTabs(){
  //   return this.apiService.get(`customer/shipments/tab`, {}, true)
  // }

  // getCustomerTabs(){
  //   return this.apiService.get(`customer/shipments/tab/active`, {}, true)
  // }

  // updateCustomerTabs(payloads: any){
  //   return this.apiService.post(`customer/shipments/tab/active`, payloads, true)
  // }

  getCustomerListing(queryString: any) {
    return this.apiService.get(`customer/listing?${queryString}`, {}, true)
  }

  fetchStatus(type: string, project_ids: number[] = []) {
    if (type == 'project-manifest' && project_ids.length) {
      const queryParams = new URLSearchParams({ type });
      project_ids.forEach(id => queryParams.append('project_ids[]', id.toString()));

      return this.apiService.get(`customer/status?${queryParams.toString()}`, {}, true)

    } else {
      return this.apiService.get(`customer/status?type=${type}`, {}, true)
    }

  }

  async getCustomerListingDetails(listing_id: number) {
    return await lastValueFrom(this.apiService.get(`customer/listing/${listing_id}`, {}, true));
  }

  changeShipmentsView() {

    return this.apiService.get(`customer/change-view`, {}, true)
  }

  getCustomerBooking(queryString: any) {
    return this.apiService.get(`customer/bookings?${queryString}`, {}, true)
  }

  async fetchColumnState(data: any) {
    return lastValueFrom(this.apiService.get(`customer/column-state`, data, true));
  }

  async updateColumnState(data: any) {
    return await lastValueFrom(this.apiService.post(`customer/column-state`, data, true));
  }

  fetchColumnStateViewFilter(type: string) {
    return lastValueFrom(this.apiService.get(`customer/get-column-state?type=${type}`, {}, true));
  }

  async fetchViewFilterList(type: string) {
    return await lastValueFrom(this.apiService.get(`customer/view-filter?type=${type}`, {}, true));
  }

  async saveViewFilter(data: any) {
    return await lastValueFrom(this.apiService.post(`customer/view-filter`, data, true));
  }

  async updateViewFilter(id: any, data: any) {
    return await lastValueFrom(this.apiService.post(`customer/view-filter/${id}`, data, true));
  }

  async deleteViewFilter(id: string, data: any) {
    return await lastValueFrom(this.apiService.delete(`customer/view-filter/${id}`, data, true));
  }

  fetchProjectBooking(data: any) {
    return this.apiService.post(`customer/project-booking`, data, true);
  }

  // fetchProjectListing(data: any) {
  //   return this.apiService.post(`customer/project-listing`, data, true);
  // }

  transformListingColumn(columns: any[]) {
    let transformedArray: any[] = [];

    transformedArray.push({
      colId: 0,
      width: 80,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      floatingFilter: false,
      cellRenderer: FirstColumnListingComponent,
      lockPosition: true,
      pinned: 'left',
      editable: false,
      filter: false,
      sortable: false,
      resizable: false
    })



    columns.forEach((i, index) => {

      switch (i.attr_name) {
        case 'status':
          break;
        case 'status_display':
          transformedArray.push({
            colId: i.attr_name,
            field: i.attr_name,
            headerName: i.front_end_view,
            floatingFilterComponentParams: { suppressFilterButton: true },
            cellRenderer: CustomBadgeRendererComponent
          });
          break;

        case 'listing_id':
          transformedArray.push({
            colId: i.attr_name,
            field: i.attr_name,
            headerName: 'Listing ID',
            filter: 'agTextColumnFilter',
            cellRenderer: function (params: any) {
              return `<a href="${environment.appUrl}/listing/${params.data.category_url_key}/${params.data.listing_id}" target="_blank">${params.data.listing_id}</a>`;
            },
            floatingFilterComponentParams: { suppressFilterButton: true }
          });

          transformedArray.push({
            colId: 'action',
            field: 'Action',
            width: 100,
            cellRenderer: ActionButtonsComponent,
            cellClass: ['d-flex', 'align-items-center'],
            floatingFilter: false,
            lockPosition: true,
            pinned: 'right',
            editable: false,
            filter: false,
            sortable: false,
            // resizable: false,
          });
          break;

        case 'quote_range':
          transformedArray.push({
            colId: i.attr_name,
            field: i.attr_name,
            headerName: 'Quote Range',
            cellClass: 'ag-right-aligned-cell',
            filter: 'agTextColumnFilter',
            filterParams: {
              textMatcher: ({
                filterText,
                value,
              }: {
                filterText: string;
                value: string | number;
              }): boolean => {
                // Remove formatting and normalize the value
                const numericValue = parseFloat(value.toString().replace(/[^\d.-]/g, ''));
                const rangeMatch = filterText.match(/\$?(\d+)[\s-]+\$?(\d+)/);

                if (rangeMatch) {
                  // Extract the range bounds
                  const lowerBound = parseFloat(rangeMatch[1]);
                  const upperBound = parseFloat(rangeMatch[2]);

                  // Check if the numeric value is within the range
                  return numericValue >= lowerBound && numericValue <= upperBound;
                }

                // Fallback to simple text search
                const normalizedValue = value.toString().replace(/[^\d.-]/g, '').toLowerCase();
                const normalizedFilterText = filterText.replace(/[^\d.-]/g, '').toLowerCase();
                return normalizedValue.includes(normalizedFilterText);
              },
            },
          });
          break;

        case 'delivery_date':
          transformedArray.push({
            colId: i.attr_name,
            field: i.attr_name,
            headerName: i.front_end_view,
            floatingFilterComponentParams: { suppressFilterButton: true },
            filter: 'agDateColumnFilter',
            cellRenderer: DateStatusComponent,
          });
          break;


        case 'distance_estimate':
          transformedArray.push({
            colId: i.attr_name,
            field: i.attr_name,
            headerName: 'Distance Estimate',
            filter: 'agTextColumnFilter',
            filterParams: {
              textMatcher: ({ filterText, value } : {filterText: string; value: string | number} ) => {
                // Normalize values: remove commas and convert to lowercase
                const normalizedValue = value.toString().replace(/[,.]/g, '').toLowerCase();
                const normalizedFilterText = filterText.replace(/[,.]/g, '').toLowerCase();

                // Return true if the value includes the filter text
                return normalizedValue.includes(normalizedFilterText);
              }
            }
          });
          break;

        case 'user_max_price':
          transformedArray.push({
            colId: i.attr_name,
            field: i.attr_name,
            headerName: 'Max Price',
            cellClass: 'ag-right-aligned-cell',
            filter: 'agTextColumnFilter',
            filterParams: {
              textMatcher: ({ filterText, value } : {filterText: string; value: string | number} ) => {
                // Normalize values: remove commas and convert to lowercase
                const normalizedValue = value.toString().replace(/[,.]/g, '').toLowerCase();
                const normalizedFilterText = filterText.replace(/[,.]/g, '').toLowerCase();

                // Return true if the value includes the filter text
                return normalizedValue.includes(normalizedFilterText);
              }
            }
          });
          break;
        case 'created_at':
          transformedArray.push({
            colId: i.attr_name,
            field: i.attr_name,
            headerName: i.front_end_view,
            floatingFilterComponentParams: { suppressFilterButton: true },
            filter: 'agDateColumnFilter',
            valueFormatter: (params: any) => formatDate(params.value),
            filterParams: filterDateParams,
          });

          break;

        case 'project_id':
          break;
        case 'load_id':
          break;
        default:
          transformedArray.push({
            colId: i.attr_name,
            field: i.attr_name,
            headerName: i.front_end_view,
            filter: 'agTextColumnFilter',
            floatingFilterComponentParams: { suppressFilterButton: true }
          });
          break;
      }

    });

    return transformedArray;
  }

  transformBookingColumn(columns: any[]) {
    let transformedArray: any[] = [];

    transformedArray.push({
      colId: 0,
      width: 80,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      floatingFilter: false,
      cellRenderer: FirstColumnListingComponent,
      lockPosition: true,
      pinned: 'left',
      editable: false,
      filter: false,
      sortable: false,
      resizable: false
    })

    columns.forEach(i => {

      switch (i.attr_name) {
        case 'status':
          break;
        case 'status_display':
          transformedArray.push({
            colId: i.attr_name,
            field: i.attr_name,
            headerName: i.front_end_view,
            floatingFilterComponentParams: { suppressFilterButton: true },
            cellRenderer: CustomBadgeRendererComponent
          });
          break;

        case 'listing_id':
          transformedArray.push({
            colId: i.attr_name,
            field: i.attr_name,
            headerName: 'Listing ID',
            filter: 'agTextColumnFilter',
            cellRenderer: function (params: any) {
              return `<a href="${environment.appUrl}/listing/${params.data.category_url_key}/${params.data.listing_id}" target="_blank">${params.data.listing_id}</a>`;
            },
            floatingFilterComponentParams: { suppressFilterButton: true }
          });

          transformedArray.push({
            colId: 'action',
            field: 'Action',
            width: 100,
            cellRenderer: ActionButtonsComponent,
            cellClass: ['d-flex', 'align-items-center'],
            floatingFilter: false,
            lockPosition: true,
            pinned: 'right',
            editable: false,
            filter: false,
            sortable: false,
            // resizable: false,
          });
          break;

        case 'provider_business_name':
          transformedArray.push({
            colId: i.attr_name,
            field: i.attr_name,
            headerName: 'Provider',
            filter: 'agTextColumnFilter',
            cellRenderer: ProviderRendererComponent,
            // cellRenderer: function (params: any) {
            //   return `<a href="${environment.appUrl}/provider/profile/${params.data.provider_username}" target="_blank">${params.data.provider_username}</a>`;
            // },
            floatingFilterComponentParams: { suppressFilterButton: true }
          });
          break;

        case 'quote_value':
          transformedArray.push({
            colId: i.attr_name,
            field: i.attr_name,
            headerName: i.front_end_view,
            cellClass: 'ag-right-aligned-cell',
            filter: 'agTextColumnFilter',
            filterParams: {
              textMatcher: ({ filterText, value } : {filterText: string; value: string | number} ) => {
                // Normalize values: remove commas and convert to lowercase
                const normalizedValue = value.toString().replace(/[,.]/g, '').toLowerCase();
                const normalizedFilterText = filterText.replace(/[,.]/g, '').toLowerCase();

                // Return true if the value includes the filter text
                return normalizedValue.includes(normalizedFilterText);
              }
            }
          });
          break;

        case 'delivery_date':
          transformedArray.push({
            colId: i.attr_name,
            field: i.attr_name,
            headerName: i.front_end_view,
            floatingFilterComponentParams: { suppressFilterButton: true },
            // filter: 'agDateColumnFilter',
            cellRenderer: DateStatusComponent,
          });
          break;

        case 'invoice_due_date':
          transformedArray.push({
            colId: i.attr_name,
            field: i.attr_name,
            headerName: i.front_end_view,
            filter: 'agDateColumnFilter',
            valueFormatter: (params: any) => formatDate(params.value),
            filterParams: filterDateParams,
            // cellRenderer: DateStatusComponent,
          });
          break;

        case 'POD':
          transformedArray.push({
            colId: i.attr_name,
            field: i.attr_name,
            headerName: i.front_end_view,
            filter: false,
            cellRenderer: AttachmentBookingsComponent,
            // cellRenderer: function (params: any) {
            //   if(params.value == false || params == '') return null;
            //   return `<a href="${environment.appUrl}/api/frontend/listing/upload-proof/doc-attachments?booking_id=${params.data.booking_id}&proof_type=delivery&source=email-attach" target="_blank">${params.value} <i class="bi bi-file-earmark"></i></a>`
            // },
            floatingFilterComponentParams: { suppressFilterButton: true }
          });
          break;
        case 'provider_username':
          break;
        case 'project_id':
          break;
        case 'load_id':
          break;

        default:
          transformedArray.push({
            colId: i.attr_name,
            field: i.attr_name,
            headerName: i.front_end_view,
            filter: 'agTextColumnFilter',
            floatingFilterComponentParams: { suppressFilterButton: true }
          });
          break;
      }
    });

    return transformedArray;
  }

}

// DATA FORMATTING
function currencyFormatter(currency: any, sign: any) {
  if (!currency) {
    return sign + '0';
  }
  var sansDec = currency.toFixed(0);
  var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return sign + `${formatted}`;
}


function formatDate(timestamp: string | number | Date) {
  if (!timestamp) return '';
  return format(new Date(timestamp), 'dd/MM/yyyy');
}

var filterDateParams: IDateFilterParams = {
  comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
    var dateAsString = formatDate(cellValue);
    if (dateAsString == null || dateAsString == '') return -1;
    var dateParts = dateAsString.split('/');
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  },
  browserDatePicker: true,
  minValidYear: 2000,
  maxValidYear: 2100,
  // inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
};


