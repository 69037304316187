import { APP_BASE_HREF } from '@angular/common';
import { Component, Inject, inject } from '@angular/core';

@Component({
  providers: [],
  template: ''
})
export class AbstractComponent {

  public baseHref:string = '';

  constructor() {
    this.baseHref = Inject(APP_BASE_HREF);
  }

}
