import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';

export const authGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {

  // if (state.url == '/shipments') {
  //   const router: Router = inject(Router);
  //   return router.createUrlTree(['access-denied']);
  // }
  return true;
};
