import { Injectable } from '@angular/core';
import { ApiService } from '../../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  protected endpoint?: string

  constructor(private apiService: ApiService) {
  }

  getHeaders() {
    const endpoint = 'menu/headers';
    return this.apiService.get(endpoint);
  }

  getNotifications(customerId: number, page: number = 1) {
    const endpoint = `customer/notifications/${customerId}`;
    return this.apiService.get(endpoint, {page: page});
  }

}
