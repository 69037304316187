<div class="modal-header">
    <h4 class="modal-title" id="modal-title">
        <i class="{{headerIcon}} {{headerIconStyle}}"></i>{{headerTitle}}
    </h4>
    <button
        type="button"
        class="btn-close"
        aria-describedby="modal-title"
        (click)="cancel()"
    ></button>
</div>
<div class="modal-body">
  <p [innerHTML]="bodyText"></p>

  <div [innerHTML]="bodyText2"></div>
</div>
<div class="modal-footer">
    <button type="button" class="btn {{btnStyle}}" (click)="confirm()">{{btnText}}</button>
</div>
