import { Injectable } from '@angular/core';
import { ApiService } from '../../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class TabService {

  constructor(private apiService: ApiService) { }

    getTabs(moduleName: string){
      return this.apiService.get(`customer/tab`, {module: moduleName}, true)
    }

    getCustomerTabs(moduleName: string){
      return this.apiService.get(`customer/tab/active`, {module: moduleName}, true)
    }

    updateCustomerTabs(payloads: any){
      return this.apiService.post(`customer/tab/active`, payloads, true)
    }

}
