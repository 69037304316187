import { Injectable } from '@angular/core';
import {ApiService} from "../../api/api.service";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  protected endpoint?: string
  constructor(private apiService: ApiService) {
    this.endpoint = 'dashboard'
  }

  getKeyValues(): Observable<any> {
    return this.apiService.get('dashboard/get-key-values', {}, true);
  }
}
