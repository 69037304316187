import { ChangeDetectorRef, Component, ViewEncapsulation,Input } from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from "ag-grid-community";
import { ShipmentService } from 'src/app/services/modules/shipments/shipment.service';
import { NgZone } from '@angular/core';

@Component({
  selector: 'app-first-column-listing',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './first-column-listing.component.html',
  styleUrls: ['./first-column-listing.component.scss']
})
export class FirstColumnListingComponent implements ICellRendererAngularComp {
  private params: any;
  private isOpen = false;
  public quickView:any;
  public listing_id:any;
  public isLoading: boolean = false;

  constructor(
    private shipmentService: ShipmentService,
    private changeDetector: ChangeDetectorRef,
    private ngZone: NgZone
  ){}

  getCustomerListingDetails(listing_id: number): void{
      this.isLoading = true;
    
      this.shipmentService.getCustomerListingDetails(listing_id).then(
        (res) => {
        //@ts-ignore
        this.quickView = res.data;
        this.isLoading = false;
      },
        (error) => {
          console.error(error);
          this.isLoading = false;
        }
      );
    
  }

  // gets called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.listing_id = this.params.data.listing_id;

  }
  // gets called whenever the cell refreshes
  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    this.listing_id = this.params.data.listing_id;
    return true;
  }

  

  

}
