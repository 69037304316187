import { NgModule }             from '@angular/core';

import { DashboardRoutingModule }     from './dashboard-routing.module';

import { DashboardComponent }         from './components/dashboard/dashboard.component';
import {NgForOf} from "@angular/common";

@NgModule({
    imports: [DashboardRoutingModule, NgForOf],
  declarations: [ DashboardComponent ]
})

export class DashboardModule {

}
