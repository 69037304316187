<div class="px-5 feedback-modal">
  <div class="modal-header px-0">
    <h4 id="modal-basic-title" class="modal-title text-secondary fw-bold">
      Help us improve Truckit For Business
    </h4>
    <button type="button" aria-label="Close" class="btn-close" (click)="modalService.dismiss('Cross click')"></button>
  </div>

  <div class="modal-body d-flex flex-column align-items-start p-0 w-100">
    <p>Required fields are marked with an asterisk. <span style="color: red"> *</span></p>
    <p>Let us know what you like or don’t like below. This will help us understand how to make Truckit For Business better.</p>
    <p>
      If you’re having technical issues or need help with how to use Truckit For Business, contact our Customer Service Team on <a href="tel:1300 859 850">1300 859 850</a>.</p>
    <div>
      <label for="feedback" class="form-label">What do you like and/or dislike? <span style="color: red"> *</span></label>
    </div>
    <textarea #feedbackTextArea class="form-control" id="feedback" rows="6" placeholder="Write your feedback here..."></textarea>
  </div>
  <div class="form-check" style="margin-top: 20px">
    <input class="form-check-input" type="checkbox" id="may-contact" #mayContact>
    <label class="form-check-label" for="may-contact">Truckit may contact me about this feedback</label>
  </div>
  <div>
    <button type="button" class="btn btn-primary mt-3" style="margin-bottom: 20px;" (click)="submitFeedback()">Send</button>
  </div>

</div>
