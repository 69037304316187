import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { ChildComponent } from '../child/child.component';
import { SidebarService } from '../../services/components/sidebar/sidebar.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MenuItem } from './menu-item';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  submenuVisibility: boolean[] = [];
  currentUrl: string = '';
  hoverIndex: number = -1;

  // Function to toggle the visibility of a submenu
  toggleSubmenu(index: number): void {
    this.submenuVisibility[index] = !this.submenuVisibility[index];
  }

  showSubmenuOnLargeScreen(index: number) {
    if (window.innerWidth > 768) {
      this.submenuVisibility[index] = true;
    }
    return;
  }
  hideSubmenuOnLargeScreen(index: number) {
    if (window.innerWidth > 768) {
      this.submenuVisibility[index] = false;
    }
    return;
  }
  isChildActive(menuItem: any): boolean {
    // Check if the menuItem has children
    if (!menuItem.children || menuItem.children.length === 0) {
      return false; // No children to check
    }

    // Check if any child route is active
    const hasActiveChild = menuItem.children.some((child: { route: string }) =>
      this.isRouteActive(child.route)
    );

    // If any child is active, make sure the parent submenu is open
    return hasActiveChild;
  }

  // Function to check if the submenu is visible
  isSubmenuVisible(index: number): boolean {
    return this.submenuVisibility[index];
  }
  menuOptions: any[] = [
    // {
    //   id: 22,
    //   name: 'Dashboard',
    //   route: '/dashboard',
    //   icon: 'https://static.truckit.net/t4b/icons/dashboard.svg',
    //   role: 'user',
    //   status: 'active',
    //   parent_id: '0',
    //   order: '0',
    //   created_at: '2023-11-01T02:59:45.000000Z',
    //   updated_at: '2023-11-01T02:59:45.000000Z',
    //   environment: 'Dev',
    // },
    // {
    //   id: 23,
    //   name: 'Shipments',
    //   route: '/shipments',
    //   icon: 'https://static.truckit.net/t4b/icons/shipment.svg',
    //   role: 'user',
    //   status: 'active',
    //   parent_id: '0',
    //   order: '0',
    //   created_at: '2023-11-01T03:00:05.000000Z',
    //   updated_at: '2023-11-01T03:00:05.000000Z',
    //   environment: 'Dev',
    // },
    // {
    //   id: 100,
    //   name: 'Incoming Payments',
    //   route: '/incoming-payments',
    //   icon: 'https://static.truckit.net/t4b/icons/shipment.svg',
    //   role: 'user',
    //   status: 'active',
    //   parent_id: '27',
    //   order: '0',
    //   created_at: '2023-11-01T03:00:05.000000Z',
    //   updated_at: '2023-11-01T03:00:05.000000Z',
    //   environment: 'Dev',
    // },
    // {
    //   id: 101,
    //   name: 'Create Customer',
    //   route: '/create-customer',
    //   icon: 'https://static.truckit.net/t4b/icons/shipment.svg',
    //   role: 'user',
    //   status: 'active',
    //   parent_id: '27',
    //   order: '0',
    //   created_at: '2023-11-01T03:00:05.000000Z',
    //   updated_at: '2023-11-01T03:00:05.000000Z',
    //   environment: 'Dev',
    // },
    // {
    //   id: 28,
    //   name: 'Projects',
    //   route: '/projects',
    //   icon: 'https://static.truckit.net/t4b/icons/shipment.svg',
    //   role: 'user',
    //   status: 'active',
    //   parent_id: '0',
    //   order: '0',
    //   created_at: '2024-09-10T07:01:47.000000Z',
    //   updated_at: '2024-09-10T07:02:20.000000Z',
    //   environment: 'Dev',
    // },
    // {
    //   id: 3,
    //   name: 'Instant Quote',
    //   route: 'instant-quote',
    //   icon: 'https://static.truckit.net/t4b/icons/lightning-charge.svg',
    //   role: 'user',
    //   status: 'active',
    //   parent_id: '0',
    //   order: '1',
    //   created_at: '2023-08-31T01:40:08.000000Z',
    //   updated_at: '2023-10-05T01:01:41.000000Z',
    //   environment: 'Prod',
    // },
    // {
    //   id: 25,
    //   name: 'Get a Quote',
    //   route: 'https://dev1.truckit.net/get-a-quote',
    //   icon: 'https://static.truckit.net/t4b/icons/lightning-charge.svg',
    //   role: 'user',
    //   status: 'active',
    //   parent_id: '0',
    //   order: '1',
    //   created_at: '2023-12-12T05:18:39.000000Z',
    //   updated_at: '2023-12-12T05:21:37.000000Z',
    //   environment: 'Prod',
    // },
    // {
    //   id: 19,
    //   name: 'My Networks',
    //   route: 'https://dev1.truckit.net/customer/private-network',
    //   icon: 'https://static.truckit.net/t4b/icons/fleet.svg',
    //   role: 'user',
    //   status: 'active',
    //   parent_id: '0',
    //   order: '2',
    //   created_at: '2023-10-04T03:12:15.000000Z',
    //   updated_at: '2023-10-05T01:02:10.000000Z',
    //   environment: 'Prod',
    // },
    // {
    //   id: 15,
    //   name: 'Financials',
    //   route: 'financials',
    //   icon: 'https://static.truckit.net/t4b/icons/financial.svg',
    //   role: 'user',
    //   status: 'active',
    //   parent_id: '0',
    //   order: '3',
    //   created_at: '2023-09-28T06:50:16.000000Z',
    //   updated_at: '2023-10-05T01:09:28.000000Z',
    //   environment: 'Prod',
    // },
    // {
    //   id: 27,
    //   name: 'Broker Summary',
    //   route: '/broker-payments',
    //   icon: 'https://static.truckit.net/t4b/icons/shipment.svg',
    //   role: 'user',
    //   status: 'active',
    //   parent_id: '0',
    //   order: '0',
    //   created_at: '2023-11-01T03:00:05.000000Z',
    //   updated_at: '2023-11-01T03:00:05.000000Z',
    //   environment: 'Dev',
    // },
    // {
    //   id: 20,
    //   name: 'Transfer Reports',
    //   route: 'https://dev1.truckit.net/customer/reports/transfer',
    //   icon: 'https://static-truckit.s3.ap-southeast-2.amazonaws.com/t4b/icons/card-checklist.svg',
    //   role: 'user',
    //   status: 'active',
    //   parent_id: '0',
    //   order: '4',
    //   created_at: '2023-10-04T03:15:42.000000Z',
    //   updated_at: '2023-10-05T02:29:28.000000Z',
    //   environment: 'Prod',
    // },
    // {
    //   id: 8,
    //   name: 'Settings',
    //   route: 'https://dev1.truckit.net/customer/profile/',
    //   icon: 'https://static.truckit.net/t4b/icons/setting.svg',
    //   role: 'user',
    //   status: 'active',
    //   parent_id: '0',
    //   order: '6',
    //   created_at: null,
    //   updated_at: '2023-10-05T01:03:08.000000Z',
    //   environment: 'Prod',
    // },
    // {
    //   id: 26,
    //   name: 'Switch to Classic',
    //   route: 'https://dev1.truckit.net/customer/profile?from_t4b=1',
    //   icon: 'https://static-truckit.s3.ap-southeast-2.amazonaws.com/icons/truckit-icon-alerts-26px.png',
    //   role: 'user',
    //   status: 'active',
    //   parent_id: '0',
    //   order: '9',
    //   created_at: '2023-12-19T06:44:37.000000Z',
    //   updated_at: '2024-02-08T07:56:46.000000Z',
    //   environment: 'Dev',
    // },
  ];

  collapseMenu: boolean = false;

  public showProdMenu = false;
  mainAppBaseURL: string = '';

  constructor(private sidebarService: SidebarService, private router: Router) {
    this.mainAppBaseURL = environment.appUrl;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.urlAfterRedirects;
      }
    });
  }

  ngOnInit(): void {
    this.sidebarService
      .getSidebar()
      .subscribe((menuOptions: MenuItem[]) => {
        this.menuOptions = menuOptions;
        console.log(menuOptions);
      })
      .add(() => {
        this.setActiveTab();
      });
    this.setActiveTab();
    this.menuOptions = this.buildHierarchy(this.menuOptions);
    console.log(this.menuOptions);
  }

  get _logoutURL() {
    return `${this.mainAppBaseURL}/logout`;
  }

  get _helpURL() {
    return 'https://help.truckit.net/support/home';
  }

  get _howItWorksURL() {
    return `${this.mainAppBaseURL}/how-it-works`;
  }

  toggleSideBar() {
    this.collapseMenu = !this.collapseMenu;
  }

  setActiveTab() {
    this.sidebarService.setActiveTab();
    // this.currentActiveTab = this.sidebarService.getActiveTab();
  }

  getCurrentActiveTab() {
    return this.sidebarService.getActiveTab();
  }

  private sortByMenuOrder(arr: { order: string }[]): { order: string }[] {
    return arr.sort((a, b) => {
      const orderA = parseInt(a.order, 10);
      const orderB = parseInt(b.order, 10);

      if (isNaN(orderA) && isNaN(orderB)) {
        return 0;
      } else if (isNaN(orderA)) {
        return 1;
      } else if (isNaN(orderB)) {
        return -1;
      }

      return orderA - orderB; // Compare the integers
    });
  }

  isRouteActive(route: string): boolean {
    const normalize = (url: string): string => {
      return url.replace(/^\/+/, '').replace(/\/+$/, '');
    };

    const normalizedRoute = normalize(route);
    const normalizedCurrentUrl = normalize(this.currentUrl);

    if (normalizedRoute == '') return false;
    return (
      normalizedCurrentUrl === normalizedRoute ||
      normalizedCurrentUrl.startsWith(normalizedRoute)
    );
  }

  public navigate(route: string) {
    if (route.startsWith('http') || route.startsWith('https')) {
      window.location.href = route;
    } else {
      this.router.navigate([route]).finally(() => {
        this.setActiveTab();
      });
    }
  }
  private buildHierarchy(flatData: any[]): any[] {
    const menuMap: { [key: string]: any } = {};
    const rootMenu: MenuItem[] = [];

    flatData.forEach((item) => {
      // Remove leading '/' from the route if it exists
      if (item.route && item.route.startsWith('/')) {
        item.route = item.route.substring(1);
      }

      menuMap[item.id] = { ...item, children: [] };
    });

    flatData.forEach((item) => {
      const currentItem = menuMap[item.id];

      if (String(item.parent_id) === '0') {
        rootMenu.push(currentItem);
      } else {
        const parentItem = menuMap[String(item.parent_id)];
        if (parentItem) {
          parentItem.children.push(currentItem);
        }
      }
    });

    console.log(rootMenu);

    return rootMenu;
  }
  onHover(index: number): void {
    this.hoverIndex = index; // Update hover index
    console.log(this.hoverIndex);
  }
}
