<div *ngIf="value || isAdjustmentInvoice">
  <span *ngIf="isAdjustmentInvoice && (params.data.credit != null && params.data.credit != 0.0)" class="badge text-bg-info text-white" (click)="openAttachment(content, params)" style="cursor: pointer;">
    <i class="bi bi-eye"></i> View Credit Note
  </span>
  <span *ngIf="isAdjustmentInvoice && (params.data.debit != null && params.data.debit != 0.0) && (params.data.credit == null || params.data.credit == 0.0)" class="badge text-bg-info text-white" (click)="openAttachment(content, params)" style="cursor: pointer;">
    <i class="bi bi-eye"></i> View Invoice
  </span>
  <span *ngIf="!isAdjustmentInvoice" class="badge text-bg-info text-white" (click)="openAttachment(content, params)" style="cursor: pointer;">
    <i class="bi bi-eye"></i> View
  </span>


  <ng-template #content let-modal>
      <div class="modal-header">
          <h4 class="modal-title">Booking ID: <span class="fw-bold ">{{ titleModal }}</span></h4>
          <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
      </div>
      <div class="modal-body d-flex align-items-center justify-content-center" style="height: 1000px !important;">
          <ng2-pdfjs-viewer [pdfSrc]="pdfSrc" class="w-100 h-100"></ng2-pdfjs-viewer>
      </div>
      <div class="modal-footer">
          <button type="button" class="btn btn-secondary text-white" (click)="modal.close('Close click')">Close</button>
      </div>
  </ng-template>
</div>

<div *ngIf="value == null && showNotice">
  <i class="bi bi-exclamation-lg text-danger fs-3"></i>
</div>
