import { Component, ViewChildren } from '@angular/core';
import { AbstractComponent } from '../abstract/abstract.component';
import { ChildComponent } from '../child/child.component';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';

@Component({
  providers: [],
  template: ''
})
export class ParentComponent extends AbstractComponent {

  @ViewChildren(ChildComponent) children?: ChildComponent[];

  increaseDecreaseInput(event: KeyboardEvent): void {
    const inputValue = (event.target as HTMLInputElement).value;

    // Check if '0' is the first character
    if (inputValue.length === 0 && event.key === '0' && event.code !== 'Backspace' && event.code !== 'Delete') {
      event.preventDefault();
    }

    // Allow only numeric input
    const isNumericInput = /^[0-9]*$/.test(event.key);

    if (!isNumericInput && event.code !== 'Backspace' && event.code !== 'Delete') {
      event.preventDefault();
    }
  }

  hasInvalidControls(control: AbstractControl): boolean {
    if (control instanceof FormControl) {
      return control.invalid;
    } else if (control instanceof FormGroup) {
      return Object.keys(control.controls).some(key => this.hasInvalidControls(control.get(key)!));
    } else if (control instanceof FormArray) {
      return control.controls.some(ctrl => this.hasInvalidControls(ctrl));
    }
    return false;
  }


}
