import { APP_INITIALIZER, Injectable, Injector, NgModule, inject } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';

import { AbstractComponent } from './components/abstract/abstract.component';
import { ParentComponent } from './components/parent/parent.component';
import { ChildComponent } from './components/child/child.component';

import { DashboardModule } from './modules/dashboard/dashboard.module';
import { ShipmentsModule } from './modules/shipments/shipments.module';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, HttpXhrBackend } from '@angular/common/http';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ApiInterceptor } from './interceptors/api/api.interceptor';
import { StorageService } from './services/storage/storage.service';
import { environment } from 'src/environments/environment';
import { ApiService } from './services/api/api.service';
import { AuthService } from './services/auth/auth.service';
import { FooterComponent } from './components/footer/footer.component';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';
import { FeedbackformComponent } from './components/feedbackform/feedbackform.component';


export function initAuth(api: ApiService, injector: Injector) {
  return () => new Promise((resolve, reject) => {

    const httpClient: HttpClient = new HttpClient(new HttpXhrBackend({
      build: () => new XMLHttpRequest()
    }));

    if (StorageService.getCookie('AUTH').length > 0) {

      httpClient.post(environment.auth_validate, { token: StorageService.getCookie('AUTH') }).subscribe({
        next: (response: any) => {
          if (!response['valid']) {
            StorageService.deleteCookie('AUTH');
            if (!location.search.startsWith('?auth_token=')) {
              localStorage.setItem('route', location.href);
            }
            location.href = environment.auth_url;
            return;
          }
          if (response['renew']) {
            StorageService.deleteCookie('AUTH');
            if (!location.search.startsWith('?auth_token=')) {
              localStorage.setItem('route', location.href);
            }
            location.href = response['renew_url'];
            return;
          }
          resolve(true);
        },
        error: (error: any) => {
          location.href = environment.auth_failed;
        }
      });

      return;
    }

    if (location.search.startsWith('?auth_token=')) {
      const urlParams = new URLSearchParams(location.search);
      const authToken = urlParams.get('auth_token');
      httpClient.post(environment.auth_callback, { otp: authToken }).subscribe({
        next: (response: any) => {
          if (!('token' in response)) {
            location.href = environment.auth_failed;
            return;
          }
          StorageService.setCookie('AUTH', response['token']);
          let url: string | null = localStorage.getItem('route');
          if (url != null) {
            localStorage.removeItem('route');
            location.href = url;
            return;
          }
          resolve(true);
        },
        error: (error: any) => {
          location.href = environment.auth_failed;
        }
      });
      return;
    }

    localStorage.setItem('route', location.href);

    location.href = environment.auth_url;
  });
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    AbstractComponent,
    ParentComponent,
    ChildComponent,
    SpinnerComponent,
    FooterComponent,
    ConfirmDialogComponent,
    InfoDialogComponent,
    FeedbackformComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    DashboardModule,
    ShipmentsModule,
    HttpClientModule,
  ],
  providers: [
    NgbActiveModal,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initAuth,
      deps: [ApiService, Injector],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
