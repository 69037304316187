import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './services/auth/guard/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/instant-quote',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: 'shipments',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./modules/shipments/shipments.module').then(
        (m) => m.ShipmentsModule
      ),
  },
  {
    path: 'projects',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./modules/projects/projects.module').then(
        (m) => m.ProjectsModule
      ),
  },
  {
    path: 'instant-quote',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./modules/instant-quote/instant-quote.module').then(
        (m) => m.InstantQuoteModule
      ),
  },
  {
    path: 'style-guide',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./modules/style-guide/style-guide.module').then(
        (m) => m.StyleGuideModule
      ),
  },
  {
    path: 'access-denied',
    loadComponent: () =>
      import('./components/access-denied/access-denied.component').then((x) => x.AccessDeniedComponent)
  },
  {
    path: 'settings',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./modules/settings/settings.module').then((x) => x.SettingsModule)
  },
  {
    path: 'financials',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./modules/financials/financials.module').then((x) => x.FinancialsModule)
  },
  {
    path: 'broker-payments',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./modules/broker-payments/broker-payments.module').then((x) => x.BrokerPaymentsModule)
  },
  {
    path: 'multi-listing',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./modules/multi-listing/multi-listing.module').then((x) => x.MultiListingModule)
  },
  {
    path: 'invoices-matching',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./modules/invoices-matching/invoices-matching.module').then((x) => x.InvoicesMatchingModule)
  },
  {
    path: 'invoices-matching/create-customer',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./modules/create-customer/create-customer.module').then((x) => x.CreateCustomerModule)
  },
  {
    path: '**',
    loadComponent: () =>
      import('./components/cms/cms.component').then((x) => x.CmsComponent)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
