<div class="wrapper" *ngIf="router.url !== '/style-guide'; else styleGuideSection ">

    <app-header></app-header>

    <div class="d-flex flex-column" style="min-height: calc(100vh - 80px);" >
        <div class="d-flex flex-row flex-grow-1">
            <div class="col-md-3 col-lg-2 p-0">
                <app-sidebar></app-sidebar>
            </div>

            <main class="col-md-9 ms-sm-auto col-lg-10 bg-body-tertiary px-lg-5 py-3">

                <router-outlet></router-outlet>

            </main>

        </div>
        <app-footer class="px-0"></app-footer>
    </div>



</div>
<ng-template #styleGuideSection>
    <router-outlet></router-outlet>
</ng-template>

<app-spinner></app-spinner>
