import { Injectable } from '@angular/core';
import { ApiService } from '../../api/api.service';
import { BehaviorSubject, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  protected endpoint?: string

  activeTab: number = 0;

  private menuOptions: any[] = [];
  public showProdMenu = false;

  constructor(private apiService: ApiService) {
    this.endpoint = 'menu/sidebar'
  }

  getSidebar() {
    return this.apiService.get(`${this.endpoint}`).pipe(
      map((res: any) => {
        
        this.menuOptions = res.data
          .filter((menu: { environment: string; }) => !this.showProdMenu || (this.showProdMenu && menu.environment === 'Prod'))
          .map((menu: { route: string; icon: string; name: string, parent_id: number, id: number, order:number }) => ({
            id: menu.id,
            route: menu.route,
            icon: menu.icon,
            title: menu.name,
            parent_id: menu.parent_id,
            order: menu.order
          }));
        return this.sortByMenuOrder(this.menuOptions);
      })
    );
  }

  setActiveTab() {
    let currentPage = window.location.pathname;
    let index = this.menuOptions.findIndex(menu => {
      return menu.route === currentPage || menu.route === currentPage.slice(1);
    });
    
    this.activeTab = index;
  }

  getActiveTab() {
    return this.activeTab;
  }

  private sortByMenuOrder(arr: { order: string }[]): { order: string }[] {
    return arr.sort((a, b) => {
        const orderA = parseInt(a.order, 10);
        const orderB = parseInt(b.order, 10);

        if (isNaN(orderA) && isNaN(orderB)) {
            return 0; // Both orders are not valid integers
        } else if (isNaN(orderA)) {
            return 1; // 'a' is not a valid integer, so it comes after 'b'
        } else if (isNaN(orderB)) {
            return -1; // 'b' is not a valid integer, so it comes after 'a'
        }

        return orderA - orderB; // Compare the integers
    });
}
}
