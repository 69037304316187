<footer class="d-flex justify-content-center bg-primary">
  <div class="d-flex flex-md-row flex-column gap-4 justify-content-between align-items-center border-light-50 border-2 w-75 pt-4 pb-5">
    <div class="d-flex align-items-center">
      <a class="navbar-brand" href="#">
        <img src="https://static.truckit.net/t4b/logos/logo-light.svg" alt="image">
      </a>
    </div>

    <div class="d-flex flex-column gap-3 justify-content-end ">
      <span class="text-white-50 small text-end">
        Truckit.net Australia's No.1 Freight Marketplace ABN 60
        627 942 027 Truckit.net Pty. Ltd. <i class="bi bi-c-circle"></i> Copyright {{currentYear}}. All rights reserved.
        Read our
        <a href="https://www.truckit.net/privacy-policy"
          target="_blank"
          class="text-secondary">Privacy Policy</a>.
      </span>
    </div>
  </div>
</footer>
