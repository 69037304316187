import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Observable, take } from 'rxjs';
import { CellClickedEvent, ColDef, GridReadyEvent, SideBarDef } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { ShipmentService } from 'src/app/services/modules/shipments/shipment.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { InfoDialogComponent } from 'src/app/components/info-dialog/info-dialog.component';


interface Status {
  id: number,
  name: string,
  value: string,
}

@Component({
  selector: 'app-shipment-bookings',
  templateUrl: './shipment-bookings.component.html',
  styleUrls: ['./shipment-bookings.component.scss'],
  providers: [ConfirmDialogComponent]
})
export class ShipmentBookingsComponent implements OnInit {



  public selectedStatus: string[] = [];
  public statuses!: any[];

  public showViewFilterSection: boolean = false;

  public showViewFilterInput = false;
  public showAddViewFilterButton = false;

  public showActionViewSearchButton = false;
  public showClearFilter = false;

  public showViewFilterSelectInput = true;
  public showViewFilterRemoveButton = false;

  public selectedView: any = null;
  public selectSavedView = false;

  public saveViewName: string | undefined;
  public saveViewArray: any[] = [];

  public currentView: any = null;

  skipEventHandler: boolean = false;

  public paginationPageSize = 20;
  public paginationPageSizeSelector: number[] | boolean = [10, 20, 50, 100];

  // model
  public viewFilterInput: string = '';
  private viewFilterType: string = 'booking';
  private module: string = 'shipment';

  public columnDefs: ColDef[] = [];

  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    // flex: 1,
    width: 130,
    filter: true,
    sortable: true,
    floatingFilter: true,
    // // lockPinned: true,
    resizable: true,
  };
  public sideBar: SideBarDef | string | string[] | boolean | null = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
      },
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
      },
    ],
    defaultToolPanel: 'filters',
    hiddenByDefault: true,
  };

  public cacheBlockSize = 10;
  public maxBlocksInCache = 10;
  public rowData!: any[];

  public customerDetails: any;
  public customerId: any = null;

  private gridApi: any;
  private gridColumnApi: any;

  fromDashboard: boolean = false;

  constructor(
    private shipmentService: ShipmentService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private _modalService: NgbModal,
    private cdr: ChangeDetectorRef,
    private confirmDialogModal: ConfirmDialogComponent, private router: Router
  ) { }

  ngOnInit(): void {

    this.authService.getUser().subscribe((res: any) => {
      if (res.status == 'success') {
        this.customerId = res.data.customer_id
      }
    });


    this.selectAllForDropdownItems(this.getStatus());
    // this.setSelectedStatus = this.statuses[0]['id'];
    this.getCustomerDetails();
    // this.getBookings();


    this.checkFromDashboard();


  }

  private checkFromDashboard() {

    this.route.queryParams.subscribe(params => {

      console.log("params['filter']", params['filter']);

      if (params['filter'] !== undefined) {

        this.fromDashboard = true;


        this.clearFilter();

        if (params['filter'] == 'complete') {
          this.initComplete();
        }

        if (params['filter'] == 'in-transit') {
          this.initInTransit();
        }


        this.clearUrlParams();

        this.showViewFilterSelectInput = true;
        this.showViewFilterSection = true;
        this.fetchViewFilterOnly();

      }
    });

  }

  private initComplete() {

    console.log("###initComplete");

    this.selectedStatus = ['complete'];

    const queryString = "status_array[]=complete";

    this.fetchDataRow(queryString).subscribe((res: any) => {
      let idSequence = 0;
      res.data.data.forEach((item: any) => {
        item.id = idSequence++;
      });
      this.rowData = res.data.data;
      this.columnDefs = this.shipmentService.transformBookingColumn(res.data.columns);
    });

    this.fromDashboard = true;

    // this.onSelectionChange();
  }

  private initInTransit() {
    console.log("###initInTransit");


    this.selectedStatus = ['in_transit', 'customer_collected', 'provider_collected'];

    const queryString = "status_array[]=customer_collected&status_array[]=in_transit&status_array[]=provider_collected";

    this.fetchDataRow(queryString).subscribe((res: any) => {
      let idSequence = 0;
      res.data.data.forEach((item: any) => {
        item.id = idSequence++;
      });
      this.rowData = res.data.data;
      this.columnDefs = this.shipmentService.transformBookingColumn(res.data.columns);
    });

    this.fromDashboard = true;

    // this.onSelectionChange();

  }



  clearUrlParams() {
    console.log("###clearUrlParams");
    this.router.navigate(['.'], { relativeTo: this.route, queryParams: {} });
  }

  applyStatusFilter(filterValue: string) {
    console.log('applyStatusFilter', filterValue)
    setTimeout(() => {

      const statuses = this.statuses.filter(s => s.name.toLowerCase() === filterValue);
      console.log('statuses', statuses.map(s => s.name));

      if (statuses.length > 0) {
        this.selectedStatus = statuses.map(status => status.value);
      } else {
        this.selectedStatus = [];
      }
      this.onSelectionChange();
    });
  }


  fetchDataRow(queryString: string = '') {
    console.log("###fetchDataRow", queryString);

    return this.shipmentService.getCustomerBooking(queryString)
      .pipe(take(1));

  }

  onGridReady(params: GridReadyEvent<any>) {
    this.gridApi = params.api;
    if (!this.fromDashboard) {
      console.log('###onGridReady');


      let queryString = '';
      let pickedStatus: string[] = [];
      pickedStatus = ['All']

      if (this.fromDashboard) {
        pickedStatus = this.selectedStatus;
        this.fromDashboard = false;
      }

      for (let i = 0; i < pickedStatus.length; i++) {
        queryString += `status_array[]=${encodeURIComponent(pickedStatus[i])}&`;
      }
      queryString = queryString.slice(0, -1);

      this.fetchDataRow(queryString).subscribe((res: any) => {
        let idSequence = 0;
        res.data.data.forEach((item: any) => {
          item.id = idSequence++;
        });
        this.rowData = res.data.data;
        this.fetchViewFilterList(true);
        // this.fetchColumnStateViewFilter();
        this.columnDefs = this.shipmentService.transformBookingColumn(res.data.columns);
      });

      this.setSideBarVisible(true)
    } else {
      this.fromDashboard = false;

    }

  }

  setSideBarVisible(value: boolean) {
    this.gridApi.setSideBarVisible(value);
  }

  statusLabelName(v: any) {
    if (v.name) return v.name;
    if (v.value) return v.value;
    return 'All Statuses';
  }

  getStatus() {
    console.log("###getStatus");
    let status: any = this.shipmentService.fetchStatus(this.viewFilterType)
      .pipe(take(1))
      .subscribe((res: any) => {

        this.statuses = res.data;
        console.log("INSIDE ###getStatus - this.statuses", this.statuses);
        this.checkFromDashboard();

      });
    console.log("###getStatus - this.statuses", this.statuses);
    return this.statuses
  }

  // Setter
  set setSelectedStatus(v: any) {
    this.selectedStatus = v
  }

  selectAllForDropdownItems(items: any[]) {
    console.log("###selectAllForDropdownItems");
    if (items) {
      let allSelect = (items: any[]) => {
        items.forEach((element: { [x: string]: string; }) => {
          element['selectedAllGroup'] = 'All Statuses';
        });
      };
      allSelect(items);
    }
  }

  async fetchViewFilterOnly() {
    console.log("###fetchViewFilterOnly");
    let lists: any = await this.shipmentService.fetchViewFilterList(this.viewFilterType);
    let viewL: any[] = lists.data;
    this.saveViewArray = viewL;

    console.log("###fetchViewFilterOnly - this.saveViewArray", this.saveViewArray);

    console.log(this.showViewFilterSelectInput && this.saveViewArray.length > 0);
    this.cdr.detectChanges();
  }

  async fetchViewFilterList(isActiveChange: boolean) {
    console.log("###fetchViewFilterList");
    let lists: any = await this.shipmentService.fetchViewFilterList(this.viewFilterType);
    let viewL: any[] = lists.data;

    if (viewL.length > 0) {
      this.showViewFilterSection = true;

      this.saveViewArray = viewL;
      // const currentViewFilter = this.saveViewArray.find(item => item.status === true);

      let currentViewFilter: any;
      let activeViewFilter: any;

      console.log("###isActiveChange",isActiveChange);

      if (isActiveChange) {
        currentViewFilter = await this.shipmentService.fetchColumnState({
          type: this.viewFilterType,
          module: this.module
        });

        currentViewFilter = currentViewFilter.data;
        console.log("###currentViewFilter",currentViewFilter);
        console.log("###saveViewArray",this.saveViewArray);

        activeViewFilter = this.saveViewArray.find(
          (item) => item.status === true
        )?._id;

        this.gridApi.applyColumnState({ state: currentViewFilter.header_filter, applyOrder: true });
        this.gridApi.setFilterModel(currentViewFilter.column_filter);
        console.log("###1activeViewFilter",activeViewFilter);
      } else {
        currentViewFilter = this.saveViewArray.find(
          (item) => item.status === true
        );

        activeViewFilter = currentViewFilter?._id;
      }
      console.log("###2activeViewFilter",activeViewFilter);
      if (activeViewFilter) {
        this.selectedView = activeViewFilter;
        // this.showClearFilter = true;
        this.showViewFilterRemoveButton = true;
        this.showAddViewFilterButton = false;
        this.showViewFilterSelectInput = true;
        this.showViewFilterInput = false;
        // this.showActionViewSearchButton = false

        this.gridApi.applyColumnState({ state: currentViewFilter.header_filter, applyOrder: true });
        this.gridApi.setFilterModel(currentViewFilter.column_filter);
      } else {
        this.showAddViewFilterButton = false;
        this.showViewFilterRemoveButton = false;
        // this.showClearFilter = false;
        this.showViewFilterSelectInput = true;
      }
    } else {
      this.saveViewArray = []
      this.gridApi.resetColumnState();
      this.gridApi.setFilterModel(null);

      this.showViewFilterSelectInput = false;
      this.showViewFilterRemoveButton = false;
      // this.showClearFilter = false;
      this.viewFilterInput = '';
      this.showAddViewFilterButton = true;
    }

    this.skipEventHandler = false;

  }

  async fetchColumnStateViewFilter() {
    console.log("###fetchColumnStateViewFilter");
    let res: any = await this.shipmentService.fetchColumnStateViewFilter(this.viewFilterType);
    this.gridApi.applyColumnState({ state: res.data, applyOrder: true });
  }

  async saveColumnState() {
    console.log("###saveColumnState");
    this.updateColumnState(this.gridApi.getFilterModel(), this.gridApi.getColumnState());

  }

  async updateColumnState(column_filter: any, header_filter: any) {
    const payload = {
      column_filter: column_filter,
      header_filter: header_filter,
      module: this.module,
      type: this.viewFilterType,
    }

    let res = await this.shipmentService.updateColumnState(payload);
    if (res.status != 'success') return;
  }

  eventHandlingListener(event: any) {
    console.log("###eventHandlingListener", event);
    if (!this.skipEventHandler && (event.type == 'dragStopped' || event.type == 'filterChanged')) {
      this.showActionViewSearchButton = true;
      if (event.type == 'dragStopped' || event.type == 'filterChanged') {
        this.saveColumnState();
      }

      if (!this.selectedView) {
        this.showViewFilterSection = true;
        this.showViewFilterSelectInput = false

        // this.showActionViewSearchButton = true;
        this.showAddViewFilterButton = true

        // this.showClearFilter = true
        // } else {
        // this.showActionViewSearchButton = true;
        // this.showAddViewFilterButton = false;

        // this.showViewFilterRemoveButton = true;
        // this.showClearFilter = true;
      }
      this.showClearFilterValidation();

    }
    this.skipEventHandler = false;

  }

  showClearFilterValidation() {
    console.log("###showClearFilterValidation");
    const filterModel = this.gridApi.getFilterModel();

    if (Object.keys(filterModel).length === 0 && filterModel.constructor === Object) {
      // The filter model is empty
      this.showClearFilter = false;

    } else {
      // The filter model is not empty
      this.showClearFilter = true;

    }
  }

  onColumnVisible(event: any) {
    this.saveColumnState();
  }

  addViewFilter() {
    console.log("###addViewFilter");
    this.viewFilterInput = '';
    this.showAddViewFilterButton = true;
    this.showViewFilterSelectInput = false;
    this.showViewFilterRemoveButton = false;


  }
  hideAddViewFilter() {
    console.log("###hideAddViewFilter");
    if (this.saveViewArray.length > 0) {
      this.showAddViewFilterButton = false;
      this.showViewFilterSelectInput = true;
      this.showViewFilterRemoveButton = this.selectedView ? true : false;
    } else {
      this.showViewFilterSection = false;
    }

  }
  selectViewFilterEvent() {
    console.log("###selectViewFilterEvent", this.selectedView);

    if (this.selectedView) {

      const currentViewFilter = this.saveViewArray.find(item => item._id === this.selectedView);
      currentViewFilter.status = true;

      const payload = {
        'name': currentViewFilter?.name,
        // 'header_filter': currentViewFilter?.header_filter,
        // 'column_filter': currentViewFilter?.column_filter,
        'status': true,
        'type': this.viewFilterType
      }

      this.shipmentService.updateViewFilter(currentViewFilter?._id, payload);
      this.updateColumnState(currentViewFilter?.column_filter, currentViewFilter?.header_filter);

      this.gridApi.applyColumnState({ state: currentViewFilter?.header_filter, applyOrder: true });
      this.gridApi.setFilterModel(currentViewFilter?.column_filter);
      this.showViewFilterRemoveButton = true;
      this.skipEventHandler = false;
    } else {
      this.showViewFilterRemoveButton = false;
      this.clearFilter()
      this.skipEventHandler = true;
    }




    this.showClearFilterValidation();

    //  Hiding the plus and cross button
    this.showActionViewSearchButton = false;






  }

  async saveViewFilter() {
    console.log("###saveViewFilter");

    const checkExistingName = this.saveViewArray.find(i => i.name === this.viewFilterInput);

    if (!checkExistingName) {
      const payload = {
        name: this.viewFilterInput,
        column_filter: this.gridApi.getFilterModel(),
        header_filter: this.gridApi.getColumnState(),
        type: this.viewFilterType,
      }

      let res = await this.shipmentService.saveViewFilter(payload);

      if (res.status != 'success') return;

      this.updateViewFilter();
      this.fetchViewFilterList(true);

    } else {

      const modalRef = this._modalService.open(InfoDialogComponent);
      modalRef.componentInstance.headerTitle = 'Duplicate name';
      modalRef.componentInstance.headerIconStyle = 'text-warning me-2';
      modalRef.componentInstance.headerIcon = 'bi bi-info';
      modalRef.componentInstance.bodyText = 'The name already exists.'
      modalRef.componentInstance.btnStyle = 'btn-info text-white';

    }

  }

  async updateViewFilter() {
    console.log("###updateViewFilter");

    this.updateColumnState(this.gridApi.getFilterModel(), this.gridApi.getColumnState())

    this.fetchViewFilterList(false);

  }

  confirmDialog() {
    console.log("###confirmDialog");
    const currentViewFilter = this.saveViewArray.find(item => item._id === this.selectedView);
    const modalRef = this._modalService.open(ConfirmDialogComponent);

    if (currentViewFilter?.name === "Default Basic") {
      modalRef.componentInstance.headerTitle = 'Reset Default Basic';
      modalRef.componentInstance.headerIconStyle = 'text-danger me-2';
      modalRef.componentInstance.headerIcon = 'bi bi-bootstrap-reboot';
      modalRef.componentInstance.whatToDelete = 'Default Basic';
      modalRef.componentInstance.subtitleText = 'Are you sure you want to <strong class="text-danger">revert the changes</strong> made on ';
      modalRef.componentInstance.btnStyle = 'btn-danger text-white';
    } else {
      modalRef.componentInstance.headerTitle = 'Remove Saved View';
      modalRef.componentInstance.headerIconStyle = 'text-danger me-2';
      modalRef.componentInstance.headerIcon = 'bi bi-trash';
      modalRef.componentInstance.whatToDelete = currentViewFilter?.name;
      modalRef.componentInstance.btnStyle = 'btn-danger text-white';
    }

    modalRef.result.then((result) => {
      console.log(result);

      if (result === 'confirm') {
        this.deleteViewFilter();
      } else {
        // User clicked on cancel or closed the dialog
        // Handle accordingly
      }
    }).catch((error) => {
      // Handle the error if any
      console.error(error);
    });

  }

  async deleteViewFilter() {
    console.log("###deleteViewFilter");
    let res = await this.shipmentService.deleteViewFilter(this.selectedView, { type: this.viewFilterType });
    this.selectedView = null;

    if (res.status != 'success') return;
    this.skipEventHandler = true;
    this.fetchViewFilterList(false);
  }


  async clearFilter() {



    this.gridApi.setFilterModel(null);

    const currentViewFilter = this.saveViewArray.find(item => item.status === true);

    if (currentViewFilter) {
      const payload = {
        'name': currentViewFilter?.name,
        // 'column_filter': currentViewFilter?.column_filter,
        // 'header_filter': currentViewFilter?.header_filter,
        'status': false,
        'type': this.viewFilterType,
      }

      await this.shipmentService.updateViewFilter(currentViewFilter?._id, payload);

    }

    this.selectedView = null;

    this.handleShowSavedViewDropDownMenu();
    this.gridApi.resetColumnState();
    this.gridApi.setFilterModel(null);
    this.viewFilterInput = '';
    this.selectedStatus = [];
    this.updateViewFilter();
    // this.onSelectionChange();

  }
  private handleShowSavedViewDropDownMenu() {
    console.log("###handleShowSavedViewDropDownMenu");
    this.showViewFilterSelectInput = true;
    this.skipEventHandler = true;
    this.showAddViewFilterButton = false;
    this.showClearFilter = false;
    this.showViewFilterRemoveButton = false;


  }
  onSelectionChange() {
    console.log('onSelectionChange');
    let pickedStatus: string[] = [];
    let isSelectedAll: boolean = this.selectedStatus.includes('All Statuses');

    if (this.statuses.length === this.selectedStatus.length || isSelectedAll) {

      this.setSelectedStatus = [{ 'selectedAllGroup': 'All Statuses' }]
      this.statuses.forEach((e, index) => {
        pickedStatus[index] = e.value
      })

    } else {
      console.log('this.selectedStatus.', this.selectedStatus);
      if (this.selectedStatus.length == 0) {
        pickedStatus = ['All']
        // this.statuses.forEach((e, index) => {
        //   pickedStatus[index] = e.value
        // })
      } else {
        console.log('else else');
        pickedStatus = this.selectedStatus
      }

    }

    let queryString = '';
    // this.gridApi.showLoadingOverlay();

    for (let i = 0; i < pickedStatus.length; i++) {
      queryString += `status_array[]=${encodeURIComponent(pickedStatus[i])}&`;
    }
    queryString = queryString.slice(0, -1);

    this.fetchDataRow(queryString).subscribe((res: any) => {
      let idSequence = 0;
      res.data.data.forEach((item: any) => {
        item.id = idSequence++;
      });
      this.rowData = res.data.data;
      this.columnDefs = this.shipmentService.transformBookingColumn(res.data.columns);
    });
  }

  compareAccounts = (item: any, selected: any) => {
    if (selected.selectedAllGroup && item.selectedAllGroup) {
      return item.selectedAllGroup === selected.selectedAllGroup;
    }
    return false;
  };

  getCustomerDetails() {
    this.authService.getUser().subscribe((res: any) => {
      this.customerDetails = res.data;
    });
  }

}
