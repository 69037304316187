import { Component, ElementRef, ViewChild } from '@angular/core';
import { ChildComponent } from '../child/child.component';
import {NgbDropdown, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { HeaderService } from 'src/app/services/components/headers/header.service';
import {AuthService} from "../../services/auth/auth.service";
import {FeedbackformComponent} from "../feedbackform/feedbackform.component";

interface MenuItem {
  id: number;
  name: string;
  route: string;
  icon: string;
  role: string;
  status: string;
  index_column: number;
  parent_id: string;
  order: string;
  created_at: string;
  updated_at: string;
}

interface NavItem {
  item: MenuItem;
  childrenColumnOne: MenuItem[];
  childrenColumnTwo: MenuItem[];
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  topLevelMenuItems: NavItem[] = [];
  customerDetails: any;
  profilePic:any=null;
  currentNotificationPage: number = 1;
  notificationList: any[] = [];
  baseBucketPath='https://truckit-profile-files.s3-ap-southeast-2.amazonaws.com/';

  @ViewChild('csDropdown') csDropdown!: NgbDropdown;

  constructor(private headerService: HeaderService, private authService: AuthService, private modalService: NgbModal) {}

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.getHeaders();
    this.getCustomerDetails().add(() => {
      this.fetchNotifications();
    });
  }

  getHeaders() {
    this.headerService.getHeaders().subscribe((res: any) => {

      const data: MenuItem[] = res.data;



      // Sort data by the order field
      data.sort((a, b) => Number(a.order) - Number(b.order));

      this.topLevelMenuItems = data
        .filter((item) => item.parent_id === '0' || item.parent_id === null)
        .map((item: MenuItem) => {
          return {
            item: item,
            childrenColumnOne: data.filter(
              (child: MenuItem) =>
                child.parent_id === item.id.toString() &&
                child.index_column === 1
            ),
            childrenColumnTwo: data.filter(
              (child: MenuItem) =>
                child.parent_id === item.id.toString() &&
                child.index_column === 2
            ),
          };
        });


    });
  }

  getCustomerDetails() {
    return this.authService.getUser().subscribe((res: any) => {
      this.customerDetails = res.data;
      this.profilePic=this.customerDetails.profile_image;
      this.profilePic=this.baseBucketPath+this.profilePic;
    });
  }


  fetchNotifications(): void {
    const customerId = this.customerDetails.customer_id;
    this.headerService.getNotifications(customerId).subscribe((data: any) => {
      this.notificationList = [...this.notificationList, ...data.data];
      this.currentNotificationPage++;
    });
  }

  getTimeAgo(timestamp: number): string {
    const now = new Date().getTime();
    const updated = new Date(timestamp * 1000).getTime();  // Convert timestamp to milliseconds if it's in seconds
    const difference = now - updated;

    const minute = 60 * 1000;
    const hour = minute * 60;
    const day = hour * 24;
    const week = day * 7;

    if (difference < minute) {
      return 'Just now';
    } else if (difference < hour) {
      return Math.round(difference / minute) + ' minutes ago';
    } else if (difference < day) {
      return Math.round(difference / hour) + ' hours ago';
    } else if (difference < week) {
      return Math.round(difference / day) + ' days ago';
    } else {
      return Math.round(difference / week) + ' weeks ago';
    }
  }

  onMenuItemClick(item: MenuItem, event: MouseEvent): void {
    if (item.name === 'Send Feedback') {
      event.preventDefault();
      const modalRef = this.modalService.open(FeedbackformComponent, { windowClass: 'feedback-modal' });
    }
  }

}
