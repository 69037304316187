import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent {
  @Input({required: true}) headerTitle!: string;
  @Input({required: true}) headerIconStyle!: string;
  @Input({required: true}) headerIcon!: string;
  @Input() bodyText: string = '';
  @Input() bodyText2: string = '';
  @Input({required: true}) btnStyle!: string;
  @Input() btnText: string = 'Ok';



  constructor(public activeModal: NgbActiveModal) {}

  confirm() {
    this.activeModal.close('confirm');
  }

  cancel() {
    this.activeModal.dismiss('cancel');
  }
}
