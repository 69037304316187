<div class="sidebar">
  <div
    class="offcanvas-md offcanvas-end"
    tabindex="-1"
    id="sidebarMenu"
    aria-labelledby="sidebarMenuLabel"
  >
    <div class="offcanvas-header">
      <div class="bg-primary py-3 px-5" id="sidebarMenuLabel">
        <img
          src="https://static.truckit.net/t4b/logos/logo-light.svg"
          alt="image"
        />
      </div>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="offcanvas"
        data-bs-target="#sidebarMenu"
        aria-label="Close"
      ></button>
    </div>
    <div
      class="offcanvas-body d-md-flex flex-column p-3 pt-lg-3 overflow-y-auto"
    >
      <ul class="nav flex-column sidebar-content">
        <li
          *ngFor="let m of menuOptions; let i = index"
          class="nav-item"
          (mouseenter)="this.showSubmenuOnLargeScreen(i)"
          (mouseleave)="this.hideSubmenuOnLargeScreen(i)"
        >
          <a
            *ngIf="m.parent_id == 0"
            class="sidebar-item text-decoration-none"
            [attr.data-bs-dismiss]="
              m.children && m.children.length === 0 ? 'offcanvas' : null
            "
            data-bs-target="#sidebarMenu"
            [ngClass]="{ active: isRouteActive(m.route) }"
            (click)="
              m.children && m.children.length === 0
                ? this.navigate(m.route)
                : this.toggleSubmenu(i)
            "
            role="button"
          >
            <span class="sidebar-item-icon">
              <img height="20px" [src]="m.icon" alt="" />
            </span>
            <span class="sidebar-item-title">{{ m.name }}</span>

            <!-- Show submenu indicator -->
            <span
              *ngIf="m.children && m.children.length > 0"
              class="submenu-indicator ms-auto"
            >
              <i
                [ngClass]="{
                  'bi bi-chevron-right': !(
                    isSubmenuVisible(i) || isChildActive(m)
                  ),
                  'bi bi-chevron-down': isSubmenuVisible(i) || isChildActive(m)
                }"
              ></i>
            </span>
          </a>

          <!-- Submenu -->
          <ul
            [ngClass]="{
              submenu: true,
              'submenu-visible': isSubmenuVisible(i) || isChildActive(m)
            }"
            class="nav flex-column ms-4 submenu"
          >
            <li *ngFor="let child of m.children" class="nav-item">
              <a
                class="sidebar-item text-decoration-none"
                [ngClass]="{ active: isRouteActive(child.route) }"
                (click)="this.navigate(child.route)"
                role="button"
                [attr.data-bs-dismiss]="
                  child.children && child.children.length === 0
                    ? 'offcanvas'
                    : null
                "
              >
                <span class="sidebar-item-icon">
                  <img height="20px" [src]="child.icon" alt="" />
                </span>
                <span class="sidebar-item-title">{{ child.name }}</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
      <div class="grow"></div>
      <hr class="my-3" />

      <ul class="nav flex-column sidebar-content">
        <li class="nav-item">
          <a
            [ngClass]="{ active: isRouteActive(_helpURL) }"
            [href]="_helpURL"
            class="sidebar-item text-decoration-none"
          >
            <span class="sidebar-item-icon">
              <i class="bi bi-question-circle"></i>
            </span>
            <span class="sidebar-item-title">Help</span>
          </a>
        </li>
        <li class="nav-item">
          <a
            [ngClass]="{ active: isRouteActive(_howItWorksURL) }"
            [href]="_howItWorksURL"
            class="sidebar-item text-decoration-none"
          >
            <span class="sidebar-item-icon">
              <i class="bi bi-info-circle"></i>
            </span>
            <span class="sidebar-item-title">How it Works</span>
          </a>
        </li>
        <li class="nav-item">
          <a
            [ngClass]="{ active: isRouteActive(_logoutURL) }"
            [href]="_logoutURL"
            class="sidebar-item text-decoration-none"
          >
            <span class="sidebar-item-icon">
              <i class="bi bi-box-arrow-right"></i>
            </span>
            <span class="sidebar-item-title">Logout</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
