<ag-grid-angular
    style="min-height: 400px; height: 550px;"
    class="ag-theme-alpine w-100"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [sideBar]="true"
    [pagination]="true"
    [paginationPageSize]="paginationPageSize"
    [cacheBlockSize]="cacheBlockSize"
    [rowData]="rowData$"
    [rowSelection]="'multiple'"
    [animateRows]="true"
    (gridReady)="onGridReady($event)"
    (cellClicked)="onCellClicked($event)"
></ag-grid-angular>