import { Component } from '@angular/core';
import { AbstractComponent } from '../abstract/abstract.component';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  providers: [],
  template: ''
})
export class ChildComponent extends AbstractComponent {

  constructor(){
    super();
  }

}
